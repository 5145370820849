import React, { useEffect } from "react";
import { useAxios } from "../../contexts/AxiosProvider";
import AdBanner from "../AdBanner";
export default function Disclaimer({ path }) {
  const { getfooterapires, footerapires } = useAxios();

  useEffect(() => {
    getfooterapires(path);
  }, []);

  return (
    <div className="text-black dark:text-white">
      <div className="flex justify-center text-3xl my-6 grow-0 shrink-0 basis-auto w-full  px-3 lg:px-6 ">
        {footerapires?.name}
      </div>

      <div
        className="text-black dark:text-white  mb-12 grow-0 shrink-0 basis-auto w-full  px-3 lg:px-6 "
        dangerouslySetInnerHTML={{
          __html: footerapires?.description,
        }}
      ></div>
    </div>
  );
}
