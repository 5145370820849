import React, { Suspense, useEffect, useState } from "react";

import App from "./App";
import Login from "./pages/Loginpage/Login";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Loading from "./components/Loading";
import Error from "./components/Error";
import TaxCalculator from "./components/taxCalculatorr/TaxCalculator";
import AddBlockExcOnProfile from "./components/AddBlockExcOnProfile";
import ForgetPassword from "./components/ForgetPassword";
import ResetPassword from "./components/ResetPassword";
import EditProfile from "./components/EditProfile";
import Contactus from "./components/Contactus";
import Coinlist from "./components/Createportfolio/Coinlist";
import { useAxios } from "./contexts/AxiosProvider";
import ConnectExchange from "./components/ConnectExchange";
import Disclaimer from "./components/footer/Disclaimer";
import Faqs from "./components/footer/Faqs";
import "./index.css";
// import CoinGabbarEarn from './components/Earn/CoinGabbarEarn';

const Dashboard = React.lazy(() => import("./components/Dashboard"));
const Portfolio = React.lazy(() => import("./components/Portfolio"));
// const ConnectExchange = React.lazy(() => import('./components/ConnectExchange'))
const Registation = React.lazy(() =>
  import("./pages/Registrationpage/Registration")
);
const Routing = () => {
  const { userDetail } = useAxios();
  const [token, settoken] = useState(localStorage.getItem("token"));
  useEffect(() => {
    // if (token === null) {
    //   navigate('/');

    // }
    settoken(localStorage.getItem("token"));
  }, [userDetail]);

  let routeslist = [
    { path: "/", widget: <App custom={<Dashboard />} /> },
    { path: "/forget-password", widget: <ForgetPassword /> },
    {
      path: "/reset-password",
      widget: <App custom={<ResetPassword />} />,
    },
    {
      path: "/disclaimer",
      widget: <App custom={<Disclaimer path={"disclaimer"} />} />,
    },
    {
      path: "/login",
      widget: token !== null ? <Navigate to="/" /> : <Login />,
    },
    {
      path: "/terms-and-conditions",
      widget: <App custom={<Disclaimer path={"terms-and-conditions"} />} />,
    },
    {
      path: "/privacy-policy",
      widget: <App custom={<Disclaimer path={"privacy-policy"} />} />,
    },
    {
      path: "/faqs",
      widget: <App custom={<Faqs />} />,
    },
    {
      path: "/registration",
      widget: token !== null ? <Navigate to="/" /> : <Registation />,
    },
    {
      path: "/portfolio",
      widget: <App custom={<Portfolio />} />,
    },
    {
      path: "/tax-calculator",
      widget: <TaxCalculator />,
    },
    {
      path: "/contact-us",
      widget: <App custom={<Contactus />} />,
    },
    {
      path: "/contactus",
      widget: <Navigate to="/contact-us" />,
    },
    {
      path: "/bugreport",
      widget: <Navigate to="/contact-us" />,
    },

    {
      path: "/editprofile",
      widget:
        token === null ? (
          <Navigate to="/login" />
        ) : (
          <App custom={<EditProfile />} />
        ),
    },
  ];
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          {routeslist.map((e, i) => {
            return <Route key={i} path={e.path} element={e.widget} />;
          })}

          <Route path="*" element={<Error />} />

          <Route
            path="/manage-portfolio"
            element={
              token == null ? (
                <Navigate to="/login" />
              ) : (
                <App custom={<AddBlockExcOnProfile />} />
              )
            }
          />
          <Route
            path="/manage-portfolio/coinlist/:key"
            element={
              token == null ? (
                <Navigate to="/login" />
              ) : (
                <App custom={<Coinlist />} />
              )
            }
          />

          <Route path="*" element={<Error />} />
          {/* <Route path="/earn" element={<App custom={<CoinGabbarEarn />} />} /> */}

          <Route
            path="/connect-exchange/:exchange"
            element={<App custom={<ConnectExchange />} />}
          />
          {/* <Redirect to="/" /> */}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routing;
