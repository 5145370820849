import React, { useState } from "react";
import { FiHome, FiUser } from "react-icons/fi";
import { RxDashboard } from "react-icons/rx";
import { IoIosStats } from "react-icons/io";
import { Link, NavLink } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import { AiFillBug } from "react-icons/ai";
import { FiMoreHorizontal } from "react-icons/fi";
import DropUp from "./DropUp";
import { MdManageAccounts } from "react-icons/md";
import { TbTools } from "react-icons/tb";

export default function BottomnavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const activeLink =
    "text-kgolden dark:text-kgolden fill-kgolden  text-md m-2 ";
  const normalLink = "text-black dark:text-white fill-white  text-md m-2";
  const { activeMenu, currentMode, setActiveMenu, screenSize } =
    useStateContext();
  const token = localStorage.getItem("token");

  const links = [
    {
      id: "1",
      name: "DashBoard",
      path: "/",
      icon: <RxDashboard className="h-5 w-5" />,
    },
    {
      id: "2",
      name: "Portfolio",
      path: "/portfolio",
      icon: <IoIosStats className="h-5 w-5" />,
    },
    {
      id: "3",
      name: "Manage Portfolio",
      path: token == null ? "/login" : "/manage-portfolio",
      icon: <MdManageAccounts className="h-5 w-5" />,
    },
    {
      id: "4",
      name: "Crypto Tax Tools",
      path: "/tax-calculator",
      icon: <TbTools className="h-5 w-5" />,
    },
  ];
  return (
    <>
      <div className="w-full ">
        {/* <section
          id="bottom-navigation"
          className="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow"
        ></section> */}
        <section
          id="bottom-navigation"
          className="block fixed text-xs text-center sm:text-sm inset-x-0 bottom-0 z-10 bg-white dark:bg-slate-800 shadow"
        >
          <div id="tabs" className="flex justify-around ">
            {links?.map((item) => (
              <NavLink
                className={({ isActive }) =>
                  isActive && item.path !== "" ? activeLink : normalLink
                }
                target={item.id == 4 ? "_blank" : ""}
                key={item.id}
                to={item.path}
              >
                <div className="flex flex-col">
                  <div className="self-center">{item.icon}</div>
                  <div> {item.name}</div>
                </div>
              </NavLink>
            ))}
            {/* <FiMoreHorizontal onClick={()=>setIsOpen(!isOpen)}>
              click
            </FiMoreHorizontal> */}
            {isOpen && <DropUp setIsOpen={setIsOpen} isOpen={isOpen} />}
          </div>
        </section>
      </div>
    </>
  );
}
