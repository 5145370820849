import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAxios } from "../../contexts/AxiosProvider";
import { useStateContext } from "../../contexts/ContextProvider";
import { ThemeProvider, createTheme } from "@mui/material";
export default function Faqs() {
  const { currentMode } = useStateContext();
  const { getfaqsapires, faqsapires } = useAxios();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  React.useEffect(() => {
    getfaqsapires();
  }, []);

  return (
    <ThemeProvider
      theme={
        currentMode === "Dark"
          ? createTheme({
              palette: {
                mode: "dark",
              },
            })
          : createTheme({
              palette: {
                mode: "light",
              },
            })
      }
    >
      <div className="p-4 ">
        <h1 className="flex justify-center my-5 text-black dark:text-white text-3xl">
          FAQs
        </h1>
        {faqsapires.map((e) => {
          return (
            <Accordion
              title={e.title}
              className="z-0  bg-gray-700 dark:bg-slate-800"
              expanded={expanded === "panel" + e.id}
              onChange={handleChange("panel" + e.id)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={"panel4bh-content" + e.id}
                id={"panel4bh-header" + e.id}
              >
                <Typography>{"Q. " + e.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div
                    className="mb-12 grow-0 shrink-0 basis-auto w-full  px-3 lg:px-6 "
                    dangerouslySetInnerHTML={{
                      __html: e.desc,
                    }}
                  ></div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </ThemeProvider>
  );
}
