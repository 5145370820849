import React, { useEffect } from "react";
import { Navbar, Footer } from './components';
import './App.css';
import { useStateContext } from "./contexts/ContextProvider";
import BottomnavBar from "./components/BottomnavBar";


const App = ({ custom }) => {
  

  const {
    setCurrentMode,
    currentMode,
    activeMenu,
  } = useStateContext();

  useEffect(() => {
    // getUser()
   
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
    /*   setCurrentColor(currentThemeColor); */
      setCurrentMode(currentThemeMode);
    }
    
  }, []);

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <div className="min-h-screen  dark:bg-main-dark-bg ">    
    {/* <img src="/images/banner.png"></img> */}
     <Navbar />

      {custom}
 
    <div>
   
     
    </div>
    <Footer />
   
      </div> 
      {activeMenu ? null:(
       <BottomnavBar/>)}
    </div>
  );
};

export default App;
