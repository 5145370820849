import { RxCross2 } from "react-icons/rx";
import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import { useAxios } from "../contexts/AxiosProvider";
import { CoinImage } from "./coinimage";
import { Dialog, OutlinedInput } from "@mui/material";

import DialogContent from "@mui/material/DialogContent";
import { useStateContext } from "../contexts/ContextProvider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";

const ExchangeModal = ({
  showExchange,
  setShowExchange,
  exchanges,
  getExchanges,
}) => {
  const [searchExc, setSearchExc] = useState("");

  const filterExchanges = exchanges?.filter((exchange) =>
    exchange?.name?.toLowerCase().includes(searchExc.toLowerCase())
  );
  const navigate = useNavigate();
  const { currentMode } = useStateContext();

  return (
    <ThemeProvider
      theme={
        currentMode === "Dark"
          ? createTheme({
              palette: {
                mode: "dark",
              },
            })
          : createTheme({
              palette: {
                mode: "light",
              },
            })
      }
    >
      <Dialog
        // fullScreen={fullScreen}
        open={showExchange}
        onClose={setShowExchange}
        scroll={"paper"}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <div className="mb-2  w-full mt-2  flex justify-between  top-0 align-middle">
            <OutlinedInput
              type="text"
              value={searchExc}
              size="small"
              onChange={(e) => setSearchExc(e.target.value)}
              className="text-gray-900 py-0 mr-4 border-gray-300 rounded-lg dark:text-gray-200 dark:bg-gray-700 "
              placeholder="Search"
            />{" "}
            <button
              className=" m-1 p-2 "
              type="button"
              onClick={() => setShowExchange(false)}
            >
              <RxCross2 />
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="inline-block relative  min-w-full max-h-48">
            {filterExchanges?.length === 0 ? (
              <p>No record found</p>
            ) : (
              filterExchanges?.map((exchange, ind) => (
                <div
                  key={exchange?.id}
                  onClick={() =>
                    navigate(`/connect-exchange/` + exchange?.exchange_id, {
                      state: exchange,
                    })
                  }
                  className="flex justify-start items-center  min-w-full  border-slate-200  dark:text-white pt-2 pb-2 hover:bg-kgolden hover:rounded-lg hover:dark:text-blue-50 hover:text-white"
                >
                  <CoinImage
                    className="h-4 mr-1"
                    image={exchange?.image}
                    alt="img"
                  />{" "}
                  <p>{exchange?.name} </p>
                </div>
              ))
            )}
          </div>
        </DialogContent>
      </Dialog>

      <ToastContainer />
    </ThemeProvider>
  );
};

export default ExchangeModal;
