import React from "react";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import AdBanner from "./AdBanner";
const list = [
  {
    key: "About us",
    url: "https://www.coingabbar.com/en/about-us",
  },
  {
    key: "Career",
    url: "https://www.coingabbar.com/en/coingabbar-cryptocurrency-career",
  },
  {
    key: "Disclaimer",
    url: "/disclaimer",
  },
  {
    key: "Terms and Conditions",
    url: "/terms-and-conditions",
  },
  {
    key: "Privacy Policy",
    url: "/privacy-policy",
  },
  {
    key: "FAQs",
    url: "/faqs",
  },
  {
    key: "Contact us",
    url: "/contact-us",
    isend: true,
  },
];
const socialcss =
  "flex justify-center align-middle  items-center hover:-top-1 top-0 transition-all duration-300 ease-in-out relative rounded-full h-8 w-8";

const Footer = () => (
  <div className=" text-black  sticky top-[100vh] pb-16  dark:text-white px-2   ">
    <hr />
    <AdBanner />
    <div className=" items-center px-4 px-sm-3 mt-5">
      <div className="row text-center">
        <div className=" text-sm">
          <ul className="flex justify-center align-middle flex-wrap">
            {list.map((e) => {
              return (
                <li key={e.key} className="mr-1">
                  <a href={e.url} target="_blank" rel="noreferrer">
                    {e.key}
                  </a>
                  {e.isend ? null : <span> |</span>}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>

    <div className="flex justify-center flex-wrap gap-4  my-6">
      <div className=" text-center  ">
        <div className="copyright">
          &copy; Copyright{" "}
          <strong>
            <a
              href="https://www.coingabbar.com"
              className="text-kgolden "
              target="_blank"
              rel="noreferrer"
            >
              Coingabbar
            </a>
          </strong>
          . All Rights Reserved
        </div>
      </div>
      <div className=" flex gap-2 mb-2">
        <a
          target="_blank"
          href="https://twitter.com/coin_gabbar"
          className={
            socialcss + " dark:bg-white p-1 shadow-md shadow-slate-800 "
          }
          title="Twitter"
          rel="noreferrer"
        >
          <svg
            viewBox="0 0 24 24"
            aria-hidden="true"
            className="r-1nao33i r-4qtqp9 r-yyyyoo r-16y2uox r-8kz0gk r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-lrsllp"
          >
            <g>
              <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
            </g>
          </svg>
          {/* <AiOutlineTwitter className="fill-white" /> */}
        </a>
        <a
          target="_blank"
          href="https://www.facebook.com/OfficialCoinGabbar"
          className={
            socialcss +
            " bg-gradient-to-r from-blue-700 via-blue-800 to-blue-900 shadow-md shadow-blue-800/50"
          }
          title="Facebook"
          rel="noreferrer"
        >
          <FaFacebookF className="fill-white" />
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/coingabbar/"
          className={
            socialcss +
            "  bg-gradient-to-tr from-pink-300 via-pink-500 to-pink-700 shadow-md shadow-pink-500/50"
          }
          title="Instagram"
          rel="noreferrer"
        >
          <AiOutlineInstagram className="fill-white" />
        </a>
        <a
          target="_blank"
          href="https://www.linkedin.com/company/79924225/admin/feed/posts/"
          className={
            socialcss +
            "  bg-gradient-to-r from-blue-600 via-blue-500 to-blue-600 shadow-md shadow-blue-600/50 "
          }
          title="Linkedin"
          rel="noreferrer"
        >
          <FaLinkedinIn className="fill-white" />
        </a>
      </div>
      <div></div>
    </div>
  </div>
);

export default Footer;
