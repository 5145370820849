import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

export default function Recaptchacomponent({ onChange, reference }) {
  return (
    <ReCAPTCHA
      ref={reference}
      sitekey={process.env.REACT_APP_CAPTCHA_KEY}
      onChange={onChange}
    />
  );
}
