import React from 'react';
import ReactDOM from "react-dom/client";
import { ContextProvider } from "./contexts/ContextProvider";
import { AxiosProvider } from "../src/contexts/AxiosProvider";
import Routing from './Routing';




const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
   <ContextProvider>
      <AxiosProvider>
        <Routing />
      </AxiosProvider>
    </ContextProvider>
  // </React.StrictMode>
);

