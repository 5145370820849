import React from "react";
import { AiFillBug } from "react-icons/ai";
import { MdManageAccounts } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { Link, NavLink, useNavigate } from "react-router-dom";

const DropUp = ({ setIsOpen, isOpen }) => {
  const token = localStorage.getItem("token");

  // const links = [
  //     {
  //         id: "3",
  //         name: "Manage Portfolio",
  //         path: token == null ? "/login" : "/manage-portfolio",
  //         icon: <MdManageAccounts className='h-5 w-5' />,
  //     },
  //     {
  //         id: "4",
  //         name: "Contact us",
  //         path: "/bugreport",
  //         icon: <AiFillBug className='h-5 w-5' />,
  //     },
  // ]
  const navigate = useNavigate();
  return (
    <div
      className="flex justify-center z-30 absolute bottom-0  bg-gray-100 dark:bg-slate-900 w-full
         text-black dark:text-white ease-in	"
    >
      <div>
        <div className="flex justify-center my-3">
          <button onClick={() => setIsOpen(!isOpen)}>
            <RxCross2 className="h-5 w-5	shadow-lg" />
          </button>
        </div>
        <ul>
          {/* <li>
                        {links?.map((item) => (
                            <NavLink
                                key={item.id}
                                to={item.path}
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                <div className=" flex ">
                                    <div className="self-center mb-10 mx-2">{item.icon}</div>
                                    <div className='self-center mb-10 mx-2'> {item.name}</div>
                                </div>
                            </NavLink>))}
                            </li> */}
          <li>
            <div
              className="flex justify-start items-center mb-10"
              onClick={() => {
                navigate(token == null ? "/login" : "/manage-portfolio");
                setIsOpen(!isOpen);
              }}
            >
              <MdManageAccounts className="h-5 w-5 mx-2" />{" "}
              <span>Manage Portfolio</span>
            </div>
          </li>
          <li>
            <Link
              to="/Contactus"
              className="flex justify-start items-center mb-10"
            >
              <AiFillBug className="h-5 w-5  mx-2 " />
              <span>Contact us</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DropUp;
