import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { toast, ToastContainer } from "react-toastify";
import Button from "./Button";
import Labelcomponent from "./Labelcomponent";
import { useAxios } from "../contexts/AxiosProvider";

const Contactus = () => {
  const { SUBMITBUGREPORT_API } = useAxios();

  const [formValues, setFormValues] = useState({
    email: "",
    message: "",
    g_recaptcha_response: "",
  });
  const [refresh, setRefresh] = useState(false);
  const [isEmailError, setIsEmailError] = useState(true);
  const [isBugError, setIsBugError] = useState(true);

  const [rendered, setRendered] = useState(false);
  const [count, setCount] = React.useState(0);
  const recaptchaRef = useRef(null);

  const reloadCaptcha = () => {
    recaptchaRef.current?.reset();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormValues({ ...formValues, [name]: value });
  };

  function onChange(value) {
    setFormValues({ ...formValues, g_recaptcha_response: value });
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setCount(0);
    if (
      formValues.g_recaptcha_response === null ||
      formValues.g_recaptcha_response === ""
    ) {
      toast.warning("Captcha is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (formValues.email === "") {
      toast.warning("Email is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsEmailError(false);
    } else if (formValues.message === "") {
      toast.warning("message is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsBugError(false);
    } else {
      setIsEmailError(true);
      setIsBugError(true);
      SUBMITBUGREPORT_API(formValues, reloadCaptcha);
      reloadCaptcha();
      setFormValues({
        email: "",
        message: "",
        g_recaptcha_response: "",
      });
    }
  };

  useEffect(() => {
    reloadCaptcha();
    setRefresh(!refresh);
  }, []);

  useEffect(() => {
    setRendered(true);
    return () => {
      setRendered(false);
    };
  }, []);

  return (
    <form
      className={"dark:bg-slate-800 xl:mx-10 my-6 px-5 py-7"}
      onSubmit={handleSubmit}
    >
      <h1 className="text-center text-2xl text-black dark:text-white">
        Report a bug, give your Suggestion / Feedback
      </h1>

      <br></br>
      <div className="flex justify-center">
        <div>
          <div className="flex justify-center flex-col">
            <Labelcomponent labelname={"Email"}></Labelcomponent>
            {/* <label htmlFor="email" className="dark:text-white text-black">
              Email
            </label> */}
            <input
              className="inputfield max-w-xl mx-a dark:bg-slate-900 dark:border-none dark:text-white
                 fill-white dark:fill-black"
              type="text"
              placeholder="Enter your Email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
            />
          </div>
          <div className="flex justify-center">
            {!isEmailError && <p className="text-red-600">Enter Your Email</p>}
          </div>
          <div className="flex justify-center flex-col">
            <Labelcomponent labelname={"Message"}></Labelcomponent>

            {/* <label htmlFor="message" className="dark:text-white text-black">
              Message
            </label> */}
            <textarea
              className="inputfield max-w-xl mx-a dark:bg-slate-900 dark:border-none
                 dark:text-white fill-white dark:fill-black"
              placeholder="Report a bug, gives your Suggestion / Feedback"
              rows="4"
              cols="50"
              name="message"
              maxLength={2000}
              value={formValues.message}
              onChange={(event) => {
                handleChange(event);
                setCount(event.target.value.length);
              }}
            ></textarea>
            <p className="flex justify-end dark:text-white">{count}/2000</p>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        {!isBugError && <p className="text-red-600">Enter Your Contact us</p>}
      </div>
      <div className="h-4"></div>
      <div className="flex justify-center">
        {rendered && (
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_CAPTCHA_KEY}
            onChange={onChange}
          />
        )}
      </div>
      <div className="h-2"></div>
      <div className="flex justify-center py-2">
        <Button text={"Submit"} type="submit" />
      </div>
      <ToastContainer />
    </form>

    // </div>
  );
};

export default Contactus;
