import React, { useEffect, useState } from "react";

import { MdKeyboardArrowDown } from "react-icons/md";
import { AiOutlineLogin } from "react-icons/ai";
import { UserProfile } from ".";
import { useStateContext } from "../contexts/ContextProvider";

import { useAxios } from "../contexts/AxiosProvider";
import Switchthemetoggle from "./ToggleTheme/Switchthemetoggle";
import { Link, NavLink } from "react-router-dom";
import { IoIosStats } from "react-icons/io";
import { MdManageAccounts } from "react-icons/md";
import { RxDashboard } from "react-icons/rx";
import { CiCalculator2 } from "react-icons/ci";

const Navbar = () => {
  const {
    setActiveMenu,
    handleClick,
    isClicked,
    setScreenSize,
    screenSize,
    currentMode,
    activeMenu,
    setMode,
  } = useStateContext();
  // const [userDetail, setUserDetail] = useState([]);
  const [token, settoken] = useState(localStorage.getItem("token"));

  const activeLink =
    "flex items-end  pl-4 pt-3 pb-2.5 rounded-lg  text-kgolden  text-md m-2  ";
  const normalLink =
    "flex items-end  pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-white hover:text-black  m-2";

  const { getUser, userDetail } = useAxios();
  const links = [
    {
      id: "1",
      name: "DashBoard",
      path: "/",
      icon: <RxDashboard />,
    },
    {
      id: "2",
      name: "Portfolio",
      path: "/portfolio",
      icon: <IoIosStats />,
    },
    {
      id: "3",
      name: "Manage Portfolio",
      path: token == null ? "/login" : "/manage-portfolio",
      icon: <MdManageAccounts />,
    },
    {
      id: "4",
      name: "Crypto Tax Tools",
      path: "/tax-calculator",
      icon: <CiCalculator2 />,
    },
    /* {
      id: "4",
      name: "Contact us",
      path: "/bugreport",
      icon: <AiFillBug />,
    }, */
    // {
    //   id: "3",
    //   name: "Coin Gabbar Earn",
    //   path: "/earn",
    //   icon: <FiShoppingBag />,
    // },
  ];
  useEffect(() => {
    getUser();
    // postDatatesting();
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  return (
    <div className="  sticky z-10 top-0 bg-white dark:bg-slate-800 shadow-sm  shadow-gray-300 dark:shadow-slate-700	">
      <div
        className={
          (currentMode === "Dark" ? "Dark " : "") +
          "flex justify-between p-2 md:ml-6 md:mr-6  relative"
        }
      >
        <Link
          to="/"
          className=" gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
        >
          <img
            height="40px"
            width="96px"
            src={
              currentMode === "Dark"
                ? "/images/logodark.svg"
                : "/images/logolight.svg"
            }
            alt="Coin Gabbar Portfolio"
            className="logo-clr dark:logo-clr-dark"
          />
        </Link>

        <div className="flex">
          <div className=" flex items-center align-middle">
            {!activeMenu
              ? null
              : links?.map((item) => (
                  <NavLink
                    target={item.id == 4 ? "_blank" : ""}
                    key={item.name}
                    id={item.id}
                    to={item?.path}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <span className=" capitalize font-bold text-sm">
                      {item.name}
                    </span>{" "}
                  </NavLink>
                ))}
          </div>
          <div className="flex items-center align-middle">
            <Switchthemetoggle setmode={setMode}></Switchthemetoggle>

            {!token ? (
              <Link to="/login">
                <button className="flex align-middle items-center justify-center ml-5 text-black dark:text-white px-3 py-1 text-[14px] rounded-[8px] border-1 dark:border-white border-black">
                  <p className="mr-1">Login</p> <AiOutlineLogin />
                </button>
              </Link>
            ) : (
              <>
                <div
                  className="flex items-center gap-2 cursor-pointer p-1 hover:bg-slate-400 hover:dark:bg-slate-700 rounded-lg mx-2"
                  onClick={() => handleClick("userProfile")}
                >
                  {userDetail?.avatar !== null ? (
                    <img
                      className="rounded-full w-8 h-8"
                      src={userDetail?.avatar}
                      alt="user-profile"
                    />
                  ) : null}
                  <MdKeyboardArrowDown className="text-gray-400 text-14" />
                </div>

                {/* <button
              // onClick={handleLogout}
              className=" mx-2 text-black dark:text-white px-3 py-1 text-[14px] rounded-[8px] border-1 border-grey "
              >
              Logout
            </button> */}
              </>
            )}

            {isClicked.userProfile && <UserProfile userDetail={userDetail} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
