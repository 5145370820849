import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Backbutton({ title, to }) {
  return (
    <div className="flex items-center ">
      <Link to={to ?? "/"} title="back">
        <FaArrowLeft className="fill-black dark:fill-white" />
      </Link>

      <div style={{ width: "100%" }}>
        <h1 className="text-center text-black dark:text-white">{title}</h1>
      </div>
    </div>
  );
}
