import { useState } from "react";
import Labelcomponent from "../Labelcomponent";
import "./regform.css";

import { useStateContext } from "../../contexts/ContextProvider";
const Regforminput = (props) => {
  const [focused, setFocused] = useState(false);
  const {
    label,
    errorMessage,
    onChange,
    checkboxChecked,
    id,
    click,
    ...inputProps
  } = props;
  const handleFocus = (e) => {
    setFocused(true);
    // StrKey.isValidEd25519PublicKey(address);
  };
  const checked = checkboxChecked === true ? "checked" : "";
  const { currentMode } = useStateContext();

  return (
    <div
      className={
        currentMode === "Dark" ? "dark text-black dark:text-white" : ""
      }
    >
      <div
        className={inputProps.type === "checkbox" ? "flex mb-3 " : "formInput"}
      >
        {inputProps.type === "checkbox" ? (
          <a
            className=" px-2 py-1"
            href="/terms-and-conditions"
            target="_blank"
          >
            <label className={"text-sm text-blue-500 dark:text-blue-300  "}>
              {label}
            </label>
          </a>
        ) : (
          <Labelcomponent labelname={label} />
        )}
        {inputProps.type === "checkbox" ? (
          <input
            title="checkbox"
            className={
              " dark:bg-slate-900 dark:border-none dark:text-white text-black-100"
            }
            {...inputProps}
            // type={inputProps.type === "password" ? "text" : "password"}
            onChange={onChange}
            {...checked}
            onBlur={handleFocus}
            /*  onFocus={() => 
            inputProps.name === "confirmPassword" && setFocused(true) 
           } */
            focused={focused.toString()}
          />
        ) : (
          <input
            className={
              "inputfield dark:bg-slate-900 dark:border-none dark:text-white text-black-100"
            }
            {...inputProps}
            // type={inputProps.type === "password" ? "text" : "password"}
            onChange={onChange}
            {...checked}
            onBlur={handleFocus}
            /*  onFocus={() => 
          inputProps.name === "confirmPassword" && setFocused(true) 
         } */
            focused={focused.toString()}
          />
        )}
        <span className="errormessge">{errorMessage}</span>
      </div>
    </div>
  );
};

export default Regforminput;
