import React from "react";
import { useStateContext } from "../contexts/ContextProvider";

export default function Labelcomponent({ labelname }) {
  const { currentMode } = useStateContext();

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <label className={"text-sm text-black dark:text-white "}>
        {labelname}
      </label>
    </div>
  );
}
