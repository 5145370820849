import Regforminput from "../../components/registrationform/Regforminput";
import "../Registrationpage/registration.css";
import "../../css/loginbutton.css";
import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAxios } from "../../contexts/AxiosProvider";
import Backbutton from "../../components/Backbutton.jsx";
import { useStateContext } from "../../contexts/ContextProvider";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "../../components";

import { useEffect } from "react";
import Recaptchacomponent from "../../components/Recaptchacomponent";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Otpverification from "./Otpverification";

function Login() {
  const [isPassword, setIsPassword] = useState(false);
  const [showloading, setshowloading] = useState(false);
  const [showotp, setshowotp] = useState(false);
  const { currentMode } = useStateContext();
  const { postData, getUserBlockchainAndExchangeapi } = useAxios();

  const [values, setValues] = useState({
    email: "",
    password: "",
    g_recaptcha_response: "",
  });
  const recaptchaRef = useRef(null);
  const navigate = useNavigate();
  // const captchaRef = useRef();
  function onChanging(value) {
    setValues({ ...values, g_recaptcha_response: value });
  }
  const inputs = [
    {
      id: 1,
      name: "email",
      type: "email",
      placeholder: "Email",
      errorMessage: "It should be a valid email address!",
      label: "Email",
      required: true,
    },
    {
      id: 2,
      name: "password",
      type: !isPassword ? "password" : "text",
      placeholder: "Password",
      errorMessage: "Password is Required!",
      label: "Password",
      /* pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`, */
      required: true,
      click: () => setIsPassword(!isPassword),
    },
  ];

  const reloadCaptcha = () => {
    recaptchaRef.current?.reset();
  };

  const handleSubmit = async (e, value) => {
    e.preventDefault();
    setshowloading(true);
    if (
      values.g_recaptcha_response === null ||
      values.g_recaptcha_response === ""
    ) {
      toast.warning("Captcha is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      reloadCaptcha();
    } else {
      try {
        let res = await postData(process.env.REACT_APP_LOGIN_API, values);
        setTimeout(() => {
          toast.success(res?.data?.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 1);
        /*  if (res?.status === 200) {
          // toast.success(res?.data?.msg, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
          // localStorage.setItem("token", res.data.token);
          // setTimeout(() => {
          //   setValues({
          //     email: "",
          //     password: "",
          //     g_recaptcha_response: "",
          //   });
          // }, 2000);
          setshowotp(true);
        } */
        if (res?.status === 200) {
          localStorage.setItem("token", res.data.token);
          var userblockchainandexchange =
            await getUserBlockchainAndExchangeapi();

          setTimeout(() => {
            // toast.success(res?.data?.msg, {
            //   position: toast.POSITION.TOP_RIGHT,
            // });
          }, 1);
          setTimeout(() => {
            setValues({ OTP: "" });

            if (
              userblockchainandexchange?.blockchain_list.length == 0 &&
              userblockchainandexchange?.exchange_list.length == 0
            )
              navigate("/", { refresh: true });
            else {
              navigate("/portfolio", { refresh: true });
            }
          }, 2000);
          localStorage.setItem("sync", "1");
        } else {
          reloadCaptcha();
        }
      } catch (error) {
        reloadCaptcha();
        toast.error(error?.response?.data?.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      setshowloading(false);
    }
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    reloadCaptcha();
  }, []);
  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <div className={"registrationcss bg-white  dark:bg-slate-900  "}>
        {showotp ? (
          <Otpverification props={values} />
        ) : (
          <form
            className={
              "customform  dark:shadow-none shadow-lg shadow-gray-500/100	bg-gray-50  dark:bg-slate-800  p-6"
            }
            onSubmit={handleSubmit}
          >
            <Backbutton title={"LOGIN"} />

            {inputs?.map((input) => (
              <div className="relative" key={input.id}>
                <Regforminput
                  key={input.id}
                  {...input}
                  value={values[input.name]}
                  onChange={onChange}
                />
                {input.name === "password" && (
                  <span
                    className="absolute right-3 top-11 cursor-pointer text-white text-xl"
                    onClick={inputs[1].click}
                  >
                    {inputs[1].type === "password" ? <FaEye /> : <FaEyeSlash />}
                  </span>
                )}
              </div>
            ))}
            <div className="grid grid-cols-1">
              <div>
                <Link
                  to="/forget-password"
                  // to="/forget-password"
                  className="text-blue float-right dark:text-blue-400"
                >
                  Forgot password.
                </Link>
              </div>

              <div>
                <Recaptchacomponent
                  reference={recaptchaRef}
                  id={Date.now}
                  onChange={onChanging}
                />
              </div>

              <div className=" justify-center my-2 mb-6 mt-6">
                {showloading ? (
                  <Button
                    type="button"
                    onClick={() => {}}
                    text={"Loading"}
                    // disabled={!isRecaptcha}
                  />
                ) : (
                  <Button
                    type="submit"
                    text={"Login"}
                    // disabled={!isRecaptcha}
                  />
                )}
              </div>
            </div>

            <div className="flex items-center black  dark:text-white">
              <p>Don't have an account yet? </p>&nbsp;
              <Link className="text-blue dark:text-blue-400" to="/registration">
                <u> Register here</u>
              </Link>
            </div>
            <ToastContainer />
          </form>
        )}
      </div>
    </div>
  );
}

export default Login;
