import { Link, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useFormik } from "formik";
import {
  connectExchangeSchema,
  connectExchangeSchemanotRequired,
} from "../Schema";
import { useAxios } from "../contexts/AxiosProvider";
import { ToastContainer } from "react-toastify";
import { useMatch } from "react-router-dom";
import { useEffect, useState } from "react";

export default function ConnectExchange() {
  const match = useMatch("/connect-exchange/*");

  const lastRoute = match.params["*"];
  const { loading, connectingExchangeAllCombined, getExchanges } = useAxios();

  const navigate = useNavigate();
  const inputclass =
    "form-control inputfield block border-2 border-gray-50 rounded-2xl  dark:bg-slate-700 dark:border-none dark:text-white text-black-100";
  const [exchanges, setexchanges] = useState({});
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        exchange_id: lastRoute,
        connection_name: "",
        api_key: "",
        secret: "",
        passphrase: "",
      },

      validationSchema:
        exchanges?.portfolio_exchange_details?.is_passphrase?.toString() === "1"
          ? connectExchangeSchema
          : connectExchangeSchemanotRequired,
      onSubmit: async (values, action) => {
        await connectingExchangeAllCombined(values, navigate);
      },
    });

  useEffect(() => {
    async function load() {
      var data = await getExchanges();
      var exchange = data.find((e) => e.exchange_id === lastRoute);
      //console.log(exchange);
      setexchanges(exchange);
    }
    load();
  }, []);

  return (
    <>
      <nav className="bg-gray-300 border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
        <div className="flex  flex-wrap justify-start items-center mx-auto max-w-screen-xl">
          <button>
            <Link to="/">
              <BiArrowBack className="dark:fill-white" />
            </Link>
          </button>
          <div className="grid place-items-center mx-auto dark:text-white">
            <span>{exchanges?.exchange_id?.toUpperCase()} EXCHANGE</span>
          </div>
        </div>
      </nav>

      {exchanges && (
        <div className="container my-12 px-6 mx-auto ">
          <section className="mb-32 text-gray-800">
            <div className="flex flex-wrap">
              <div className="grow-0 shrink-0 basis-auto mb-12  lg:mb-0 w-full lg:w-5/12 px-3 lg:px-6">
                <form onSubmit={handleSubmit}>
                  <div className="form-group mb-6">
                    <input
                      type="text"
                      name="connection_name"
                      value={values.connection_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={inputclass}
                      id="exampleInput7"
                      placeholder="Connection name"
                    />
                    {errors.connection_name && touched.connection_name ? (
                      <p className="text-red-700"> {errors.connection_name} </p>
                    ) : null}
                  </div>
                  <div className="form-group mb-6">
                    <label htmlFor="email"> API Key</label>
                    <input
                      type="text"
                      name="api_key"
                      value={values.api_key}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={inputclass}
                      id="api"
                      placeholder="Enter API key"
                    />
                    {errors.api_key && touched.api_key ? (
                      <p className="text-red-700"> {errors.api_key} </p>
                    ) : null}
                  </div>
                  <div className="form-group mb-6">
                    <label htmlFor="email"> Secret Key</label>
                    <input
                      type="secret"
                      name="secret"
                      value={values.secret}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={inputclass}
                      id="secret"
                      placeholder="Enter Secret key"
                    />
                    {errors.secret && touched.secret ? (
                      <p className="text-red-700"> {errors.secret} </p>
                    ) : null}
                  </div>
                  {exchanges?.portfolio_exchange_details?.is_passphrase.toString() ===
                  "1" ? (
                    <div className="form-group mb-6">
                      <label htmlFor="passphrase"> Pass phrase</label>
                      <input
                        type="text"
                        name="passphrase"
                        value={values.passphrase}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={inputclass}
                        id="passphrase"
                        placeholder="Enter Passphrase "
                      />
                      {/* {errors.passphrase && touched.passphrase ? <p className="text-red-700"> {errors.passphrase} </p> : null} */}
                      {exchanges?.portfolio_exchange_details?.is_passphrase.toString() ===
                        "1" &&
                      errors.passphrase &&
                      touched.passphrase ? (
                        <p className="text-red-700"> {errors.passphrase} </p>
                      ) : null}
                    </div>
                  ) : null}
                  <button
                    type="submit"
                    className=" rounded-lg bg-pink-500 fill-slate-800 dark:bg-pink-500 py-3 px-6 font-sans text-xs font-bold uppercase text-white "
                  >
                    {loading ? <span>Loading...</span> : <span>Connect</span>}
                  </button>
                </form>
              </div>
              <div className="grow-0 shrink-0 basis-auto w-full lg:w-7/12">
                <h1 className="dark:text-white text-black ">
                  How to Add Your Account
                </h1>
                <div
                  className=" grow-0 shrink-0 basis-auto w-full  px-3 lg:px-6 text-black dark:text-white"
                  dangerouslySetInnerHTML={{
                    __html:
                      exchanges?.portfolio_exchange_details
                        ?.create_api_key_steps,
                  }}
                ></div>
              </div>
            </div>
          </section>
          <ToastContainer />
        </div>
      )}
      {!exchanges && (
        <div className="dark:text-white max-w-screen-xl text-black flex justify-center items-center py-20">
          Not Found
        </div>
      )}
    </>
  );
}
