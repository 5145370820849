import axios from "axios";
import { client } from "../axios";

import { createContext, useState, useContext } from "react";

import { toast } from "react-toastify";

const AxiosContext = createContext();

export const AxiosProvider = ({ children }) => {
  const [wallet_address, setwallet_address] = useState("");
  const [getUserBalance, setGetUserBalance] = useState(null);
  const [getUserHolding, setGetUserHolding] = useState(null);
  const [blockchains, setBlockchains] = useState(null);
  const [walletData, setWalletData] = useState(null);
  const [exchanges, setExchanges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [syncloading, setsyncLoading] = useState(false);
  const [syncflag, setsyncflag] = useState([]);
  const [isactivecoinlist, setisactivecoinlist] = useState([]);
  const [doughnutLabel, setDoughnutLabel] = useState([]);
  const [doughnutData, setDoughnutData] = useState([]);
  const [getTokenslistByUniqueid, setgetTokenslistByUniqueid] = useState({});

  const [barsLabel, setBarsLabel] = useState(null);
  const [barsData, setBarsData] = useState(null);
  const [footerapires, setfooterapires] = useState(null);
  const [faqsapires, setfaqsapires] = useState([]);
  const [userDetail, setUserDetail] = useState([]);
  const [getUserBlockchainAndExchange, setgetUserBlockchainAndExchange] =
    useState({});

  const [token, settoken] = useState(localStorage.getItem("token"));

  const getData = async (endPoint, header) => {
    try {
      let response = await client.get(endPoint, header);

      return response;
    } catch (error) {}
  };

  const postData = async (endPoint, body, headers) => {
    try {
      let response = await client.post(endPoint, body, headers);

      return response;
    } catch (error) {
      toast.error(error?.response?.data?.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const postDatatesting = async () => {
    try {
      let old = Date.now();
      let urls = [];
      let result = [];
      for (let i = 1; i <= 14; i++) {
        urls.push(`/getCryptoMarketData?page=${i}`);
      }
      await Promise.all(
        urls.map((url) =>
          axios
            .create({
              baseURL:
                "http://192.168.1.83/BackendCoingabbar/public/index.php/api/v1/portfolio_project",
            })
            .get(url)
            .then((res) => {
              //console.log(res);
              // result.push(res?.data);
            })
        )
      );
      // //console.log(result);
      //console.log(Date.now() - old);
    } catch (error) {
      toast.error(error?.response?.data?.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const deleteData = async (endPoint, id, header) => {
    try {
      const response = await client.delete(`${endPoint}${id}`, header);

      return response;
    } catch (error) {}
  };

  const putData = async (endPoint, id, header, body) => {
    try {
      const response = await client.put(`${endPoint}${id}`, header, body);
      return response;
    } catch (error) {}
  };

  const userSaveKeys = async (data, header) => {
    try {
      const res = await postData(
        process.env.REACT_APP_USERSAVEKEYS_API,
        data,
        header
      );
      return res;
    } catch (error) {
      toast.error(error?.response?.data?.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const getUserBalanceData = async (data, header) => {
    try {
      const res = await postData(
        process.env.REACT_APP_GETUSERBALANCE_API,
        data,
        header
      );
      return res;
    } catch (err) {}
  };
  const SUBMITBUGREPORT_API = async (data, callback) => {
    try {
      const res = await postData(
        process.env.REACT_APP_SUBMITBUGREPORT_API,
        data
      );
      if (res?.status === 200) {
        toast.success(res.data.msg);
      } else {
        callback();
      }
    } catch (err) {}
  };

  const getUserHoldingData = async (data, header) => {
    try {
      const res = await postData(
        process.env.REACT_APP_GETUSERHOLDING_API,
        data,
        header
      );
      return res;
    } catch (err) {}
  };
  const resendOTP = async (email) => {
    try {
      const res = await postData("/api/resendOTP", { email: email });
      toast.success(res?.data?.msg);
      return res;
    } catch (err) {}
  };

  const getExchanges = async () => {
    const data = await postData(process.env.REACT_APP_GET_EXCHANGES_API);
    setExchanges(data?.data?.data?.exchange_list);
    return data?.data?.data?.exchange_list;
  };
  const getBlockchains = async () => {
    const data = await postData(process.env.REACT_APP_GET_BLOCKCHAIN_API);
    setBlockchains(data?.data?.data?.blockchain_list);
  };

  const deleteWallet = async (unique_id, type) => {
    try {
      var res = await postData("/api/v1/deleteWallet", {
        type: type ?? localStorage.getItem("type"),
        unique_id: unique_id ?? localStorage.getItem("unique_id"),
      });
      if (res?.data?.status) {
        localStorage.removeItem("type");
        localStorage.removeItem("unique_id");
      }
      return res;
    } catch (error) {}
  };

  const getfooterapires = async (parm) => {
    try {
      var res = await getData("/api/footer_page_api?type=" + parm);

      setfooterapires(res?.data?.data);
    } catch (error) {}
  };
  const getfaqsapires = async () => {
    try {
      var res = await getData("/api/faq_api");

      setfaqsapires(res?.data?.data);
    } catch (error) {}
  };
  const userDeletePortfolio = async (uniqueid) => {
    let token2 = localStorage.getItem("token");
    try {
      var res = await postData(
        process.env.REACT_APP_GETUSERDELETEPORTFOLIO_API,
        {
          unique_id: uniqueid,
        },
        {
          headers: { Authorization: `Bearer ${token2}` },
        }
      );

      toast.success(res?.data?.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      await getUserBlockchainAndExchangeapi();
    } catch (error) {}
  };
  const userDeletePortfolioByWalletAddress = async (uniqueid) => {
    var token2 = localStorage.getItem("token");
    try {
      var res = await postData(
        "/api/v1/user/userDeletePortfolioByWalletAddress",
        {
          wallet_address: uniqueid,
        },
        {
          headers: { Authorization: `Bearer ${token2}` },
        }
      );

      toast.success(res?.data?.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      await getUserBlockchainAndExchangeapi();
    } catch (error) {}
  };
  const handleWallet = async () => {
    let token2 = localStorage.getItem("token");

    try {
      setLoading(true);
      if (token2 === null && wallet_address != null && wallet_address !== "") {
        var deletewalletres = await deleteWallet(wallet_address, "BLOCKCHAIN");
      }
      const data = await postData(
        process.env.REACT_APP_FINDBLOCKCHAIN_API,
        { wallet_address: wallet_address, type: "BLOCKCHAIN" },
        token2 == null
          ? {}
          : {
              headers: { Authorization: `Bearer ${token2}` },
            }
      );
      var responsedata = data?.data?.data;
      setWalletData(responsedata);

      setwallet_address("");
      if (data.data.status === true) {
        if (token2 == null) {
          localStorage.setItem("unique_id", responsedata?.wallet_address);
          localStorage.setItem("type", responsedata?.type);
        }
        const res = await postData(
          process.env.REACT_APP_GETUSERBALANCE_API,
          {
            wallet_address: responsedata?.wallet_address,
            type: responsedata?.type,
          },
          token2 == null
            ? {}
            : {
                headers: { Authorization: `Bearer ${token2}` },
              }
        );

        setGetUserBalance(res.data.data);

        return data?.data?.status;
      } else {
        return false;
      }
    } catch (error) {}

    setLoading(false);
  };

  const BlockchainBalanceData = async () => {
    try {
      const res = await postData(process.env.REACT_APP_GETUSERBALANCE_API, {
        wallet_address: localStorage.getItem("unique_id"),
        type: localStorage.getItem("type"),
      });

      setGetUserBalance(res.data.data);
    } catch (error) {}
  };
  const ExchangeBalanceData = async () => {
    try {
      const res = await postData(process.env.REACT_APP_GETUSERBALANCE_API, {
        wallet_id: localStorage.getItem("unique_id"),
        type: localStorage.getItem("type"),
      });

      setGetUserBalance(res.data.data);
    } catch (error) {}
  };
  const getTokensByUniqueid = async (unique_id) => {
    setgetTokenslistByUniqueid({});
    setLoading(true);
    let token2 = localStorage.getItem("token");

    try {
      const res = await postData(
        "/api/v1/user/getTokensByUniqueid",

        { unique_id: unique_id },
        token2 == null
          ? {}
          : {
              headers: { Authorization: `Bearer ${token2}` },
            }
      );
      //console.log(res?.data);
      setgetTokenslistByUniqueid(res?.data ?? {});
    } catch (error) {}
    setLoading(false);
  };

  const UserBalanceWithLogin = async () => {
    let token2 = localStorage.getItem("token");
    try {
      const res = await postData(
        process.env.REACT_APP_GETUSERBALANCE_API,
        {},
        token2 == null
          ? {}
          : {
              headers: { Authorization: `Bearer ${token2}` },
            }
      );

      setGetUserBalance(res?.data?.data);
    } catch (error) {}
  };

  const connectingExchangeAllCombined = async (values, navigate) => {
    let token2 = localStorage.getItem("token");
    try {
      setLoading(true);
      await userSaveKeys(
        values,
        token2 == null
          ? {}
          : {
              headers: { Authorization: `Bearer ${token2}` },
            }
      ).then(async (response) => {
        var data = response?.data.data;

        if (response?.status === 200) {
          localStorage.setItem("unique_id", data?.wallet_id);
          localStorage.setItem("type", data?.type);
          await getUserBalanceData(
            {
              wallet_id: data?.wallet_id,
              type: data?.type,
            },
            token2 == null
              ? {}
              : {
                  headers: { Authorization: `Bearer ${token2}` },
                }
          ).then((res) => {
            setGetUserBalance(res?.data?.data);
            if (response?.status === 200) {
              navigate("/portfolio");
            }
          });
        }
      });
    } catch (error) {}
    setLoading(false);
  };

  const showAllHoldingdsData = async () => {
    let token2 = localStorage.getItem("token");
    if (token2) {
      try {
        setLoading(true);
        const res = await postData(
          process.env.REACT_APP_ALLCOMBINED_API,
          {
            // unique_id: localStorage.getItem("unique_id"),
            // type: walletData.type
          },
          token2 == null
            ? {}
            : {
                headers: { Authorization: `Bearer ${token2}` },
              }
        );
        BlockchainAndExchangeAndAll(res?.data?.data);
      } catch (error) {}
      setLoading(false);
    }
  };
  const addBlockchiansWithChainName = async (
    connection_name,
    wallet_address,
    selectedOptions
  ) => {
    let token2 = localStorage.getItem("token");
    if (token2) {
      try {
        setLoading(true);
        const res = await postData(
          process.env.REACT_APP_ADDBLOCKCHAINWITHCHAINNAME_API,
          {
            connection_name: connection_name ?? "",
            chain_ids: selectedOptions,
            wallet_address: wallet_address,
          },
          token2 == null
            ? {}
            : {
                headers: { Authorization: `Bearer ${token2}` },
              }
        );
        toast[res?.data?.status === true ? "success" : "error"](res?.data?.msg);
        if (res?.data?.status === true) {
          await getUserBlockchainAndExchangeapi();
        }
      } catch (error) {
        toast.error(error.data.msg);
      }
      setLoading(false);
    }
  };
  const EDITCONNECTIONNAME = async (connection_name, unique_id) => {
    let token2 = localStorage.getItem("token");
    if (token2) {
      try {
        setLoading(true);
        const res = await postData(
          process.env.REACT_APP_EDITCONNECTIONNAME_API,
          {
            connection_name: connection_name ?? "",
            unique_id: unique_id ?? "",
          },
          token2 == null
            ? {}
            : {
                headers: { Authorization: `Bearer ${token2}` },
              }
        );

        toast.success(res?.data?.msg);
        await getUserBlockchainAndExchangeapi();
      } catch (error) {}
      setLoading(false);
    }
  };

  const userReUpdateBlockchainByWalletId = async (unique_id) => {
    let token2 = localStorage.getItem("token");
    if (token2) {
      try {
        setisactivecoinlist([]);
        setsyncflag([...syncflag, unique_id]);
        const res = await client.post(
          "/api/v1/user/userReUpdateBlockchainByWalletId",
          {
            unique_id: unique_id ?? "",
          },
          token2 == null
            ? {}
            : {
                headers: { Authorization: `Bearer ${token2}` },
              }
        );
        let temp = syncflag;
        temp.pop();
        setsyncflag([...temp]);
        return true;
      } catch (error) {
        let temp = syncflag;
        temp.pop();
        setsyncflag([...temp]);
      }
    }
  };
  const updateStatusCoin = async (coinid) => {
    let token2 = localStorage.getItem("token");
    if (token2) {
      try {
        let arrayt = getTokenslistByUniqueid?.data ?? [];

        let objIndex = arrayt?.findIndex(
          (obj) => obj.holding_id.toString() === coinid.toString()
        );

        arrayt[objIndex].is_active = arrayt[objIndex].is_active === 0 ? 1 : 0;
        let data = {
          status: getTokenslistByUniqueid.status,
          msg: getTokenslistByUniqueid.msg,
          data: arrayt,
          name: getTokenslistByUniqueid.name,
          wallet_address: getTokenslistByUniqueid.wallet_address,
        };

        const res = await postData(
          "/api/v1/user/updateStatusCoin",
          {
            holding_id: coinid ?? "",
          },
          token2 === null
            ? {}
            : {
                headers: { Authorization: `Bearer ${token2}` },
              }
        );
        setgetTokenslistByUniqueid(data);
        toast.success(res?.data?.msg);
      } catch (error) {
        toast.error(error?.toString());
      }
    }
  };
  const userReUpdateExchangeChangeByWalletId = async (unique_id) => {
    let token2 = localStorage.getItem("token");
    if (token2) {
      try {
        setsyncflag([...syncflag, unique_id]);
        const res = await client.post(
          "/api/v1/user/userReUpdateExchangeChangeByWalletId",
          {
            unique_id: unique_id ?? "",
          },
          token2 == null
            ? {}
            : {
                headers: { Authorization: `Bearer ${token2}` },
              }
        );
        let temp = syncflag;
        temp.pop();
        setsyncflag([...temp]);
        return true;
      } catch (error) {
        let temp = syncflag;
        temp.pop();
        setsyncflag([...temp]);
      }
    }
  };
  // const showAllHoldingdsData = () => {
  //   if (token) {
  //     try {
  //       setLoading(true);
  //       postData("/api/v1/allCombined", {
  //       }, token == null ? {} : {
  //         headers: { Authorization: `Bearer ${token}` },
  //       }).then((res) => {
  //         const names = res?.data?.data?.map(item => item?.symbol)
  //         const newNames = names?.slice(0, 4).concat("other")
  //         setGetUserHolding(res?.data?.data)
  //         setDoughnutLabel(newNames)
  //         setLoading(false);
  //       })
  //     } catch (error) {
  //       setLoading(false);

  //     }
  //   }
  // }z

  const showExchangesData = async () => {
    let token2 = localStorage.getItem("token");
    if (token2 || localStorage.getItem("type") === "EXCHANGE") {
      try {
        setLoading(true);
        const res = await postData(
          "/api/v1/combinedExchange",
          {
            unique_id: localStorage.getItem("unique_id"),
          },
          token2 == null
            ? {}
            : {
                headers: { Authorization: `Bearer ${token2}` },
              }
        );

        await BlockchainAndExchangeAndAll(res?.data?.data);
      } catch (error) {}
      setLoading(false);
    }
  };

  const showBlockchainsData = async () => {
    let token2 = localStorage.getItem("token");
    if (token2 || localStorage.getItem("type") === "BLOCKCHAIN") {
      try {
        setLoading(true);
        const res = await postData(
          process.env.REACT_APP_COMBINEDBLOCKCHAIN_API,
          {
            unique_id:
              token2 == null || token2 == ""
                ? localStorage.getItem("unique_id").toString()
                : token2,
            // type: walletData.type
          },
          token2 == null
            ? {}
            : {
                headers: { Authorization: `Bearer ${token2}` },
              }
        );
        BlockchainAndExchangeAndAll(res?.data?.data);
      } catch (error) {}
      setLoading(false);
    }
  };

  async function BlockchainAndExchangeAndAll(data) {
    setLoading(true);

    const names = data?.map((item) => item?.symbol);
    const newNames =
      data?.length > 5
        ? names?.slice(0, 6).concat("OTHER")
        : names?.slice(0, data?.length);
    setDoughnutLabel(newNames);

    const _list = data?.length > 5 ? data?.slice(0, 6) : data;

    const hold = _list?.map((item) =>
      item?.perc_of_hodl !== 0
        ? item?.perc_of_hodl?.slice(0, -1)
        : item?.perc_of_hodl
    );

    const holdNum =
      hold?.length > 1
        ? hold?.map(Number)?.reduce((acc, cur) => acc + cur)
        : hold;

    const holdingData = [...hold, parseFloat(100 - holdNum).toFixed(2)];

    setDoughnutData(holdingData);
    const barLabel = names?.length > 5 ? names?.slice(0, 6) : names;
    setBarsLabel(barLabel);
    const _list2 = data?.length > 5 ? data?.slice(0, 6) : data;
    const bars = _list2?.map((item) => item?.total_value);

    setBarsData(bars);
    setGetUserHolding(data);
    setLoading(false);
  }

  const singleExchange = async (id) => {
    let token2 = localStorage.getItem("token");
    try {
      setLoading(true);
      const res = await postData(
        process.env.REACT_APP_SINGLEEXCHANGE_API,
        {
          exchange_id: id,
          unique_id: localStorage.getItem("unique_id"),
        },
        token2 == null
          ? {}
          : {
              headers: { Authorization: `Bearer ${token2}` },
            }
      );
      await BlockchainAndExchangeAndAll(res?.data?.data);

      // setGetUserHolding(res?.data?.data)
      // setLoading(false)
    } catch (error) {}
    setLoading(false);

    // }
  };

  const singleBlockchain = async (id) => {
    let token2 = localStorage.getItem("token");
    try {
      setLoading(true);
      const res = await postData(
        process.env.REACT_APP_SINGLEBLOCKCHAIN_API,
        {
          chain_id: id,
          unique_id: localStorage.getItem("unique_id"),
        },
        token2 == null
          ? {}
          : {
              headers: { Authorization: `Bearer ${token2}` },
            }
      );
      await BlockchainAndExchangeAndAll(res?.data?.data);
    } catch (error) {}

    setLoading(false);
  };

  const handleLogout = async () => {
    let token2 = localStorage.getItem("token");
    const res = await getData(process.env.REACT_APP_LOGOUT_API, {
      headers: { Authorization: `Bearer ${token2}` },
    });

    localStorage.removeItem("token");
    localStorage.clear();
    window.location.reload();
  };

  const getUser = async () => {
    let token2 = localStorage.getItem("token");

    if (token2) {
      const res = await getData(process.env.REACT_APP_GET_USER_API, {
        headers: { Authorization: `Bearer ${token2}` },
      });
      setUserDetail(res?.data?.data);
      return res?.data?.data;
    }
  };

  const getUserBlockchainAndExchangeapi = async () => {
    var token2 = localStorage.getItem("token");
    if (token2) {
      const res = await getData(
        process.env.REACT_APP_GETUSERBLOCKCHAINEXCHAIN_API,
        {
          headers: { Authorization: `Bearer ${token2}` },
        }
      );
      setgetUserBlockchainAndExchange(res?.data?.data);
      return res?.data?.data;
    }
  };
  return (
    <AxiosContext.Provider
      value={{
        getData,

        postData,
        deleteData,
        putData,
        wallet_address,
        setwallet_address,
        handleWallet,
        getUserBalance,
        getUserHolding,
        loading,
        getExchanges,
        exchanges,
        getTokenslistByUniqueid,
        setExchanges,
        getUserBlockchainAndExchangeapi,
        userSaveKeys,
        getUserBalanceData,
        isactivecoinlist,
        getUserHoldingData,
        userDeletePortfolio,
        // connectingExchange,
        SUBMITBUGREPORT_API,
        connectingExchangeAllCombined,
        showExchangesData,
        EDITCONNECTIONNAME,
        showBlockchainsData,
        showAllHoldingdsData,
        singleExchange,
        updateStatusCoin,
        singleBlockchain,
        ExchangeBalanceData,
        UserBalanceWithLogin,
        BlockchainBalanceData,
        userReUpdateExchangeChangeByWalletId,
        getBlockchains,
        addBlockchiansWithChainName,
        userDeletePortfolioByWalletAddress,
        userReUpdateBlockchainByWalletId,
        getTokensByUniqueid,
        resendOTP,
        token,
        getUserBlockchainAndExchange,
        blockchains,
        doughnutLabel,
        syncflag,
        doughnutData,
        barsLabel,
        postDatatesting,
        barsData,
        setsyncflag,
        handleLogout,
        setUserDetail,
        userDetail,
        getUser,
        getfooterapires,
        footerapires,
        getfaqsapires,
        faqsapires,
        setsyncLoading,
        syncloading,
      }}
    >
      {children}
    </AxiosContext.Provider>
  );
};

export const useAxios = () => useContext(AxiosContext);
