import React, { useEffect } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

import { Button } from ".";
import { useAxios } from "../contexts/AxiosProvider";
import { useStateContext } from "../contexts/ContextProvider";

const UserProfile = ({ userDetail }) => {
  const navigate = useNavigate();
  const { setIsClicked, initialState } = useStateContext();

  const { handleLogout, getUser } = useAxios();

  useEffect(() => {
    getUser();
  }, []);
  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg ">
      <div className="flex justify-between items-center">
        <div
          className="absolute right-0  dark:bg-slate-800  z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="float-right">
            {/* <Button
              icon={<MdOutlineCancel />}
              onClick={() => setIsClicked(initialState)}
              color="rgb(153, 171, 180)"
              bgHoverColor="light-gray"
              size="2xl"
              className='text-2xl'
              borderRadius="50%"
            /> */}
            <button onClick={() => setIsClicked(initialState)}>
              <MdOutlineCancel className="mx-2 my-1 dark:fill-white" />
            </button>
          </div>
          <div className="py-1 mx-2 my-4 dark:text-white" role="none">
            <div
              to="#"
              className=" block py-1 px-2 mx-3 my-1  text-sm font-bold"
              role="menuitem"
              tabIndex="-1"
              id="menu-item-0"
            >
              {userDetail?.name}
            </div>

            <Link
              to="/editprofile"
              onClick={() => setIsClicked(initialState)}
              className=" block py-1 px-2 mx-3 my-1 text-sm hover:text-kgolden hover:font-bold"
              role="menuitem"
              tabIndex="-1"
              id="menu-item-1"
            >
              Profile
            </Link>

            <button
              type="submit"
              className=" rounded-lg bg-kgolden mx-3 my-1 py-1 px-2 font-sans text-sm 
             text-white  "
              role="menuitem"
              tabIndex="-1"
              id="menu-item-3"
              onClick={handleLogout}
            >
              Log out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
