import React from "react";
import { string } from "yup";

export default function Numberformatcomponent({ val, fixed, type }) {
  console.log(val);
  return (
    <td
      // title={
      //   (val == null || typeof val != string ? 0.0 : val)
      //     ?.toFixed(fixed)
      //     .match(/^-?\d*\.?0*\d{0,2}/)[0]
      // }
      className="p-2 text-end align-middle bg-transparent border-b whitespace-nowrap shadow-transparent"
    >
      <p className="mb-0 font-semibold leading-tight ">
        <span className="font-semibold ">{type ? "$" : null}</span>{" "}
        {val === null || val == 0 || typeof val === string
          ? 0.0
          : val > 1
          ? val
          : parseFloat(val)
              .toFixed(fixed)
              .match(/^-?\d*\.?0*\d{0,2}/)[0]}
        <span className="font-semibold ">{type ? null : " %"}</span>{" "}
      </p>
    </td>
  );
}
