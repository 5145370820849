import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useAxios } from "../contexts/AxiosProvider";
import { useStateContext } from "../contexts/ContextProvider";
import Regforminput from "./registrationform/Regforminput";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ResetPassword = () => {
  const [resetForm, setResetForm] = useState({
    password: "",
    confirm_password: "",
    token: "",
  });
  // const [password, setPassword] = useState("")
  // const [confirm_password, setconfirm_password] = useState("")

  const [isPassword, setisPassword] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [isConfirmPass, setisConfirmPass] = useState(false);

  const { currentMode } = useStateContext();
  const { postData } = useAxios();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setResetForm({ ...resetForm, [name]: value });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const token = searchParams.get("token");
    // setToken(token);
    setResetForm({ ...resetForm, token: token });
  }, []);

  const inputs = [
    {
      id: 1,
      name: "password",
      type: !isPassword ? "password" : "text",
      placeholder: "new password",
      errorMessage:
        "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!",
      label: "Password",
      pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
      click: () => setisPassword(!isPassword),
    },
    {
      id: 2,
      name: "confirm_password",
      type: !isConfirmPass ? "password" : "text",
      placeholder: "confirm password",
      errorMessage: "Passwords don't match!",
      label: "Password",
      pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
      click: () => setisConfirmPass(!isConfirmPass),
    },
  ];

  const ResetPassword = async () => {
    setisloading(true);
    try {
      const res = await postData("/api/resetPassword", resetForm);
      setTimeout(() => {
        toast?.success(res?.data?.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 1);
      if (res?.status === 200) {
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      }
    } catch (error) {
      toast.error(error?.response?.data?.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setisloading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    ResetPassword();
  };

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <div className={"registrationcss bg-white dark:bg-slate-900 "}>
        <form
          className={
            "customform  bg-gray-50 dark:bg-slate-800 dark:shadow-none shadow-lg shadow-gray-500/100 p-6"
          }
          onSubmit={handleSubmit}
        >
          <h4 className="text-center text-black dark:text-white">
            Reset Password
          </h4>

          <br></br>
          {inputs?.map((input) => (
            <div className="relative">
              <Regforminput
                key={input.id}
                {...input}
                value={resetForm[input.name]}
                onChange={handleChange}
                click={input.click}
              />
              <span
                className="absolute right-3 top-11 cursor-pointer text-white text-xl"
                onClick={input.click}
              >
                {input.type === "password" ? (
                  <FaEye />
                ) : (
                  <FaEyeSlash className="" />
                )}
              </span>
            </div>
          ))}

          {/* <input className='inputfield dark:bg-slate-900 dark:border-none dark:text-white
         fill-white dark:fill-black' type="password" name="password" placeholder="new password" value={password.password}
                        onChange={(e) => setPassword(e.target.value)} />
                    <input className='inputfield dark:bg-slate-900 dark:border-none dark:text-white
         fill-white dark:fill-black' type="password" name="confirm_password" placeholder="confirm password" value={password.confirm_password}
                        onChange={(e) => setconfirm_password(e.target.value)} /> */}
          <button className="loginbutton bg-blue dark:bg-kpurple" type="submit">
            {isloading ? "Loading..." : "Submit"}
          </button>
          <ToastContainer />
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
