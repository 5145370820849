import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useAxios } from "../contexts/AxiosProvider";
import { CoinImage } from "./coinimage";

export default function EditconnectionnameModel({
  showeditModal,
  setshoweditModal,
  connectionname,
  unique_id,
}) {
  const [connection_name, setconnectionname] = useState();
  const { EDITCONNECTIONNAME } = useAxios();
  useEffect(() => {
    setconnectionname(connectionname);
  }, [showeditModal]);

  return (
    <div>
      {showeditModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
            <div className="relative w-auto my-6 mx-auto ">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white dark:bg-gray-700  outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid  w-400 small-screen-modal border-slate-200 rounded-t">
                  <form className="w-full bg-white dark:bg-gray-700 ">
                    <div className="flex">
                      <div className="flex float-left w-full">
                        Edit connection name
                      </div>
                      <button
                        className="float-center mb-6"
                        type="button"
                        onClick={() => setshoweditModal(false)}
                      >
                        <RxCross2 />
                      </button>
                    </div>

                    <div className="form-group mb-6">
                      <input
                        type="text"
                        name="name"
                        value={connection_name}
                        onChange={(e) => setconnectionname(e.target.value)}
                        className="form-control block
                                            w-full
                                            px-3
                                            py-1.5
                                            text-base
                                            font-normal
                                            text-gray-700
                                            bg-white bg-clip-padding
                                            border border-solid border-gray-300
                                            rounded
                                            transition
                                            ease-in-out
                                            m-0
                                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        id="name"
                        placeholder="Connection Name "
                      />
                    </div>
                  </form>
                </div>
                <div className="flex items-center justify-end p-1 py-3 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={async () => {
                      if (connection_name === null || connection_name == "") {
                        toast.error("Connection name required");
                      } else {
                        await EDITCONNECTIONNAME(connection_name, unique_id);
                        setshoweditModal(false);
                        setconnectionname("");
                      }
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </>
      ) : null}
    </div>
  );
}
