import React, { useEffect, useState } from "react";
import { useAxios } from "../../contexts/AxiosProvider";
import { Link, useLocation } from "react-router-dom";
import { CoinImage } from "../coinimage";
import Numberformatcomponent from "../Numberformatcomponent";
import { BiArrowBack } from "react-icons/bi";
import { Button } from "@mui/material";

import { ToastContainer } from "react-toastify";

export default function Coinlist() {
  const {
    getTokensByUniqueid,
    getTokenslistByUniqueid,
    loading,

    updateStatusCoin,
  } = useAxios();
  const { state } = useLocation();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [coinid, setcoinid] = useState(null);
  const [message, setmessage] = useState("");

  const BlockchaintableRow = [
    "#",
    "Coin Name",
    "Qty",
    "Rate",
    "Total",
    "Status",
  ];

  useEffect(() => {
    if (state.unique_id !== null) {
      getTokensByUniqueid(state.unique_id);
    }
  }, []);

  return (
    <div className="w-full h-full">
      <nav className="bg-white w-full px-4  border-gray-200  lg:px-6 py-2.5 dark:bg-gray-800">
        <div className="flex ">
          <button>
            <Link to="/manage-portfolio">
              <BiArrowBack className="dark:fill-white" />
            </Link>
          </button>

          <div className="flex w-full justify-center  dark:text-white">
            <div> {getTokenslistByUniqueid?.name ?? ""}</div>
            {" : "}
            <div className="break-all">
              {getTokenslistByUniqueid?.wallet_address}
            </div>
          </div>
        </div>
      </nav>
      <div className="w-full overflow-scroll">
        <table className="w-full  mb-0 border-gray-200 text-slate-500">
          <thead className="align-bottom thead">
            <tr>
              {BlockchaintableRow?.map((val, i) => (
                <th
                  key={val + i}
                  className="py-3 text-end font-bold uppercase border-b border-gray-200 
                                         text-slate-400 opacity-70"
                >
                  {val}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="6 ">
                  <div className="flex items-center justify-center m-12">
                    <div
                      className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full mx-auto text-end"
                      role="status"
                    ></div>
                  </div>
                </td>
              </tr>
            ) : getTokenslistByUniqueid?.data?.length === 0 ? (
              <>
                <tr>
                  <td colSpan="6 ">
                    <div className="flex items-center h-14 border-b  justify-center ">
                      No record found
                    </div>
                  </td>
                </tr>
              </>
            ) : (
              <>
                {getTokenslistByUniqueid?.data?.map((val, i) => (
                  <tr
                    className="p-2 text-end align-middle bg-transparent border-b whitespace-nowrap shadow-transparent"
                    key={i}
                  >
                    <td className="p-2 text-end align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                      {i + 1}
                    </td>
                    <td className="p-2 text-start align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                      <div className="flex">
                        <CoinImage image={val?.logo} /> {val?.full_name}
                      </div>
                    </td>
                    <td className="p-2 text-end text-xs align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                      {val?.total_qty_in_mb}
                    </td>

                    <Numberformatcomponent
                      val={val?.price}
                      fixed={12}
                      type={true}
                    />
                    <Numberformatcomponent
                      val={(val?.price ?? 0) * (val?.total_qty ?? 1)}
                      fixed={12}
                      type={true}
                    />
                    <td className="p-2 text-end text-xs align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                      <Button
                        id={val?.holding_id + "_btn"}
                        onClick={() => {
                          setmessage(
                            val?.is_active.toString() !== "1"
                              ? "Active"
                              : "Inactive"
                          );
                          setConfirmDelete(true);
                          setcoinid(val.holding_id);
                        }}
                      >
                        {val?.is_active.toString() === "1"
                          ? "Active"
                          : "Inactive"}
                      </Button>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
      {confirmDelete && (
        <div className=" flex justify-center w-screen items-center  fixed delete-modal  z-50  p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full">
          <div className="relative w-screen h-full  md:h-auto">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <button
                type="button"
                className="absolute top-3 right-3.5 text-gray-400  hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-hide="popup-modal"
                onClick={() => setConfirmDelete(false)}
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="p-6 text-center">
                <svg
                  aria-hidden="true"
                  className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                  Are you sure you want to {message} this Coin?
                </h3>
                <button
                  data-modal-hide="popup-modal"
                  type="button"
                  onClick={async () => {
                    await updateStatusCoin(coinid);
                    setConfirmDelete(false);
                  }}
                  className=" text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                >
                  {loading ? "Updating..." : "Yes, I'm sure"}
                </button>
                {loading ? null : (
                  <button
                    data-modal-hide="popup-modal"
                    type="button"
                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    onClick={() => setConfirmDelete(false)}
                  >
                    No, cancel
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
