import React, { useState } from "react";
import { OutlinedInput } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { useAxios } from "../contexts/AxiosProvider";
import { CoinImage } from "./coinimage";
import { Dialog } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useStateContext } from "../contexts/ContextProvider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";

const BlockchainModal = ({ showModal, setShowModal, blockchains }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [availblechain, setavailblechain] = useState([]);
  const [wallet_address, setwallet_address] = useState(null);
  const [connection_name, setconnectionname] = useState();

  const { addBlockchiansWithChainName, loading } = useAxios();
  const handleChange = (value) => {
    if (value !== null) {
      setSelectedOptions(
        selectedOptions?.includes(value.chain_id)
          ? selectedOptions?.filter((option) => option !== value.chain_id)
          : [...selectedOptions, value.chain_id]
      );
    }
  };
  function filteradd(address) {
    let resarray = [];
    switch (true) {
      case address.startsWith("0x") && address.length === 42 /* "bitgert" */:
        resarray = [
          "ethereum",
          "binance-smart-chain",
          "avalanche",
          "celo",
          "ethereumpow",
          "ethereum-classic",
          "theta",
          "vechain",
          "moonriver",
          "moonbeam",
          "optimistic-ethereum",
          "polygon-pos",
          "arbitrum",
          "fantom",
          "digitalbits",
          "osmosis",
          "digibyte",
          "coredao",
          "digibyte",
        ].reverse();
        break;

      case address.startsWith("osmo"):
        resarray = ["osmosis"];
        break;
      case address.startsWith(1) || address.startsWith(3):
        resarray = ["bitcoin", "polkadot", "waves"];
        break;
      case address.startsWith("bc1"):
        resarray = ["bitcoin"];
        break;
      case address.toUpperCase().startsWith("L") || address.startsWith("M"):
        resarray = ["litecoin"];
        break;
      case address.startsWith("addr"):
        resarray = ["cardano"];
        break;
      case address.startsWith("T"):
        resarray = ["tron", "tron-staking"];
        break;

      case address.startsWith("GA") ||
        address.startsWith("GB") ||
        address.startsWith("GC") ||
        address.startsWith("GD"):
        resarray = ["stellar", "digitalbits"];
        break;
      case address.startsWith("D"):
        resarray = ["dogechain"];
        break;
      //  case address.startsWith('r'):
      // resarray = ["polygon-pos"];
      // break;
      case address.startsWith("a"):
        resarray = ["near-protocol"];
        break;
      // case address.startsWith('r'):
      // resarray = ["celo"];
      // break;
      // case address.startsWith('1'):
      // resarray = ["polkadot"];
      // break;
      // case address.startsWith('r'):
      // resarray = ["kusama"];
      // break;
      // case address.startsWith('r'):
      // resarray = "moonriver";
      // break;
      case address.startsWith("q") || address.startsWith("3"):
        resarray = ["bitcoin-cash"];
        break;
      case address.startsWith("tz"):
        resarray = ["tezos"];
        break;
      case address.startsWith("xdc"):
        resarray = ["xdc-network"];
        break;
      case address.startsWith("V") || address.startsWith("G"):
        resarray = ["algorand"];
        break;
      case address.startsWith("c") ||
        address.startsWith("cosmos") ||
        address.startsWith("k"):
        resarray = ["cosmos"];
        break;
      //  case address.startsWith('r'):
      // resarray =[ "litecoin"];
      // break;
      //  case address.startsWith('r'):
      // resarray =[ "avalanche"];
      // break;
      // case address.startsWith('r'):
      // resarray =[ "moonbeam"];
      // break;
      //  case address.startsWith('r'):
      // resarray = ["optimistic-ethereum"];
      // break;
      //  case address.startsWith('r'):
      // resarray =[ "dash"];
      // break;
      //  case address.startsWith('r'):
      // resarray = ["hedera-hashgraph"];
      // break;
      case address.startsWith("terra1"):
        resarray = [
          "terra",
          "terra-lunc" /* 'terra-staking', 'terra-lunc-staking' */,
        ];
        break;
      //  case address.startsWith('terra1'):
      // resarray = ["terra-lunc"];
      // break;
      //  case address.startsWith('r'):
      // resarray =[ "zcash"];
      // break;
      //  case address.startsWith('r'):
      // resarray =[ "omni"];
      // break;
      //  case address.startsWith('r'):
      // resarray = ["staked-us"];
      // break;
      //  case address.startsWith('r'):
      // resarray = ["ethereum-classic"];
      // break;
      //  case address.startsWith('r'):
      // resarray =[ "bitgert"];
      // break;
      case address.startsWith("f1"):
        resarray = ["filecoin"];
        break;
      case address.startsWith("R"):
        resarray = ["ravencoin"];
        break;
      //  case address.startsWith('r'):
      // resarray =[ "waves"];
      // break;
      //  case address.startsWith('r'):
      // resarray =[ "theta"];
      // break;
      case address.startsWith("zil1"):
        resarray = ["zilliqa"];
        break;
      case address.startsWith("one1"):
        resarray = ["harmony"];
        break;
      //  case address.startsWith('r'):
      // resarray =[ "vechain"];
      // break;
      case address.startsWith("bnb"):
        resarray = ["binancecoin"];
        break;
      //  case address.startsWith('r'):
      // resarray =[ "ethereumpow"];
      // break;
      //  case address.startsWith('r'):
      // resarray = ["internet-computer"];
      // break;
      //  case address.startsWith('r'):
      // resarray = ["terra-staking"];
      // break;
      //  case address.startsWith('r'):
      // resarray = ["terra-lunc-staking"];
      // break;
      //  case address.startsWith('r'):
      // resarray = ["tron-staking"];
      // break;
      case address.startsWith("h"):
        resarray = "icon";
        break;
      case address.startsWith("w") && address.length == 12:
        resarray = "eos";
        break;
      case address.length <= 44 && address.length >= 32:
        resarray = ["solana"];
        break;
      default:
        break;
    }
    let result = blockchains?.map((a) => a.chain_id);
    blockchains?.sort(
      (a, b) => resarray?.indexOf(b.chain_id) - resarray.indexOf(a.chain_id)
    );
    // let result = blockchains.map((a) => a.chain_id);
    setSelectedOptions([]);
    setavailblechain(
      resarray.length !== 0
        ? resarray
        : wallet_address !== null && wallet_address.trim() !== ""
        ? result
        : []
      /*  [
            "ethereum",
              "moonriver",
          "moonbeam",
          "optimistic-ethereum",
            "binance-smart-chain",
            "avalanche",
            "celo",
            "ethereumpow",
            "ethereum-classic",
            "theta",
            "vechain",
            "bitcoin",
            "polkadot",
            "waves",
            "litecoin",
            "cardano",
            "tron",
            "tron-staking",
            "solana",
            "stellar",
            "dogechain",
            "near-protocol",
            "bitcoin-cash",
            "tezos",
            "xdc-network",
            "algorand",
            "cosmos",
            "terra",
            "terra-lunc",
            "filecoin",
            "ravencoin",
            "zilliqa",
            "harmony",
            "binancecoin",
            "icon",
            "eos",
          ] */
    );
  }
  const { currentMode } = useStateContext();
  return (
    <ThemeProvider
      theme={
        currentMode === "Dark"
          ? createTheme({
              palette: {
                mode: "dark",
              },
            })
          : createTheme({
              palette: {
                mode: "light",
              },
            })
      }
    >
      <Dialog
        // fullScreen={fullScreen}
        open={showModal}
        onClose={setShowModal}
        scroll={"paper"}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <div>
            {" "}
            {"Select Chain and add wallet address"}{" "}
            {/*  {loading ? null : (
                  <button
                    className="float-center mb-6"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                      setSelectedOptions([]);
                      setavailblechain([]);
                    }}
                  >
                    <RxCross2 />
                  </button>
                )} */}
          </div>
        </DialogTitle>
        <DialogContent>
          <div>
            <OutlinedInput
              // sx={{
              //   input: {
              //     color: "white",
              //     background: "slate",
              //   },
              // }}
              className="mb-4 p-0"
              fullWidth={true}
              type="text"
              name="name"
              onChange={(e) => setconnectionname(e.target.value.trim())}
              id="name"
              placeholder="Connection Name (optional)"
            />

            <OutlinedInput
              fullWidth={true}
              className="mb-4"
              type="text"
              name="wallet-address"
              onChange={(e) => {
                filteradd(e.target.value);
                setwallet_address(e.target.value);
              }}
              id="wallet-address"
              placeholder="Enter Wallet Address"
            />

            <p className=" mb-2">
              Select wallet which available for your wallet address
            </p>

            <div className="  py-2 mx-auto grid blockchainpopup   ">
              {blockchains?.map((blockchain, ind) => (
                <div
                  key={blockchain?.chain_id + ind}
                  className={
                    (availblechain?.includes(blockchain?.chain_id)
                      ? selectedOptions?.includes(blockchain.chain_id)
                        ? "bg-kgolden2 text-white rounded-lg "
                        : ""
                      : " opacity-50") + " flex p-2 items-start"
                  }
                  onClick={() => {
                    wallet_address !== null && wallet_address.trim() !== ""
                      ? handleChange(blockchain)
                      : availblechain?.includes(blockchain?.chain_id)
                      ? handleChange(blockchain)
                      : handleChange(null);
                  }}
                >
                  <CoinImage image={blockchain?.image}></CoinImage>

                  <div
                    className="truncate text-xs"
                    value={blockchain?.chain_id}
                  >
                    {blockchain?.shortname}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {loading ? null : (
            <Button
              autoFocus
              onClick={() => {
                setShowModal(false);
                setSelectedOptions([]);
                setavailblechain([]);
              }}
            >
              cancel
            </Button>
          )}
          <Button
            onClick={async () => {
              if (wallet_address === null || wallet_address.trim() === "") {
                toast.error("Wallet Address required");
              } else if (
                selectedOptions === null ||
                selectedOptions?.length === 0
              ) {
                toast.error("Select Alteast one Blockchain ");
              } else {
                if (!loading) {
                  await addBlockchiansWithChainName(
                    connection_name,
                    wallet_address,
                    selectedOptions
                  );
                }
                setShowModal(false);
                setSelectedOptions([]);
                setavailblechain([]);
              }
            }}
            autoFocus
          >
            {loading ? "Loading" : "Connect"}
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer />
    </ThemeProvider>
  );
};

export default BlockchainModal;
