import React from "react";

export default function AdBanner() {
  return (
    <div>
      <ins
        class="adsbygoogle"
        // style="display:block"
        data-ad-client="ca-pub-3683577924679113"
        data-ad-slot="1964956468"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
    </div>
  );
}
