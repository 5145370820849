import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useAxios } from "../contexts/AxiosProvider";
import { useStateContext } from "../contexts/ContextProvider";
import Backbutton from "./Backbutton";
import Labelcomponent from "./Labelcomponent";
import Button from "./Button";
import ReCAPTCHA from "react-google-recaptcha";
import Recaptchacomponent from "./Recaptchacomponent";

const ForgetPassword = () => {
  const { currentMode } = useStateContext();
  const { postData } = useAxios();
  const [isloading, setisloading] = useState(false);

  const [email, setEmail] = useState("");
  const [g_recaptcha_response, setg_recaptcha_response] = useState("");
  const recaptchaRef = useRef(null);

  const reloadCaptcha = () => {
    recaptchaRef?.current?.reset();
  };
  function onChanging(value) {
    setg_recaptcha_response(value);
  }
  const forgetPassword = async () => {
    setisloading(true);

    try {
      const res = await postData("/api/forgotPassword", {
        email: email,
        g_recaptcha_response: g_recaptcha_response,
      });
      if (res?.status === 200) {
        toast.success(res?.data?.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        reloadCaptcha();
      }
    } catch (error) {
      toast.error(error?.response?.data?.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setisloading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (g_recaptcha_response === null || g_recaptcha_response === "") {
      toast.warning("Captcha is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (email === null || email === "") {
      toast.warning("Email is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      if (!isloading) {
        forgetPassword();
      }
    }
  };
  useEffect(() => {
    reloadCaptcha();
  }, []);
  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <div className={"registrationcss bg-white dark:bg-slate-900 "}>
        <form
          className={
            "customform  bg-gray-50 dark:bg-slate-800 dark:shadow-none shadow-lg shadow-gray-500/100 p-6"
          }
          onSubmit={handleSubmit}
        >
          <Backbutton title={"FORGOT PASSWORD"} />
          <div className="h-4"></div>

          <br></br>
          <Labelcomponent labelname={"Enter your Email Address"} />

          <input
            className="inputfield dark:bg-slate-900 dark:border-none dark:text-white
           fill-white dark:fill-black"
            type="text"
            placeholder="Enter your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="h-4"></div>

          <Recaptchacomponent onChange={onChanging} reference={recaptchaRef} />

          <div className="h-4"></div>
          <Button
            // className="loginbutton bg-blue dark:bg-kpurple"
            type="submit"
          >
            {isloading ? "Loading..." : " Send reset link"}
          </Button>
          <div className="h-4"></div>

          <ToastContainer />
        </form>
      </div>
    </div>
  );
};

export default ForgetPassword;
