import React from "react";
const Button = ({
  icon,
  bgColor,
  color,
  bgHoverColor,
  disabled,
  text,
  borderRadius,
  type,
  children,
  onClick,
}) => {
  return (
    <div className="flex justify-center ">
      <button
        type={type}
        // onClick={() => setIsClicked(initialState)}
        onClick={onClick}
        style={{ backgroundColor: bgColor, color, borderRadius }}
        disabled={disabled}
        className={`px-4 mt-3 py-1 text-white bg-gradient-to-r from-kgolden  to-amber-500 font-sans text-md font-bold shadow-md hover:shadow-lg rounded-lg hover:drop-shadow-xl hover:bg-${bgHoverColor}`}
      >
        {icon} {text} {children}
      </button>
    </div>
  );
};

export default Button;
