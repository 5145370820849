import React, { useState, useRef, useEffect } from "react";
import Regforminput from "../../components/registrationform/Regforminput";
import "../Registrationpage/registration.css";
import "../../css/loginbutton.css";

import { useNavigate } from "react-router-dom";
import { useAxios } from "../../contexts/AxiosProvider";
import Backbutton from "../../components/Backbutton.jsx";
import { useStateContext } from "../../contexts/ContextProvider";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "../../components";

function Otpverification({ props }) {
  const Ref = useRef(null);
  const [showloading, setshowloading] = useState(false);

  const [isPassword, setIsPassword] = useState(false);
  const { currentMode } = useStateContext();
  const navigate = useNavigate();
  const { postData, resendOTP, getUserBlockchainAndExchangeapi } = useAxios();

  const [values, setValues] = useState({ OTP: "" });
  const [timer, setTimer] = useState("00:00:00");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("00:02:00");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 120);
    return deadline;
  };

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  // Another way to call the clearTimer() to start
  // the countdown is via action event from the
  // button first we create function to be called
  // by the button
  const onClickReset = () => {
    clearTimer(getDeadTime());
  };
  const inputs = [
    {
      id: 1,
      name: "OTP",
      type: "number",
      placeholder: "Enter OTP",
      label: "Enter OTP",
      required: true,
    },
  ];

  const handleSubmit = async (e, value) => {
    e.preventDefault();
    setshowloading(true);
    try {
      if (!showloading) {
        let res = await postData("/api/twoFactorOtpVerify", {
          email: props.email,
          otp: values.OTP,
        });

        if (res?.status === 200) {
          localStorage.setItem("token", res.data.token);
          var userblockchainandexchange =
            await getUserBlockchainAndExchangeapi();

          setTimeout(() => {
            toast.success(res?.data?.msg, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }, 1);
          setTimeout(() => {
            setValues({ OTP: "" });

            if (
              userblockchainandexchange?.blockchain_list.length == 0 &&
              userblockchainandexchange?.exchange_list.length == 0
            )
              navigate("/", { refresh: true });
            else {
              navigate("/portfolio", { refresh: true });
            }
          }, 2000);
          localStorage.setItem("sync", "1");
        }
        setshowloading(false);
      }
    } catch (error) {
      setshowloading(false);
      toast.error(error?.response?.data?.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <div className={"   bg-white dark:bg-slate-900 "}>
        <form
          className={
            "  rounded-lg bg-gray-50 dark:bg-slate-800 dark:shadow-none shadow-lg shadow-gray-500/100 p-6"
          }
          onSubmit={handleSubmit}
        >
          <Backbutton title={"OTP"} />

          <div className="dark:text-white text-black my-3">{props.email}</div>

          {inputs?.map((input) => (
            <div className="relative" key={input.id}>
              <Regforminput
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
              />
            </div>
          ))}
          {timer !== "00:00:00" ? (
            <h2 className="text-black dark:text-white">{timer}</h2>
          ) : (
            <button
              className="dark:text-white text-black"
              onClick={async () => {
                onClickReset();
                await resendOTP(props.email);
              }}
            >
              RESEND
            </button>
          )}

          <div className="grid grid-cols-1">
            <div className=" justify-center my-2 mb-6 mt-6">
              <Button
                type="submit"
                text={showloading ? "Loading..." : "Submit"}
                // disabled={!isRecaptcha}
              />
            </div>
          </div>

          <ToastContainer />
        </form>
      </div>
    </div>
  );
}

export default Otpverification;
