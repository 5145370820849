import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { FaRegEdit } from "react-icons/fa";
import { IoCopy } from "react-icons/io5";
import { useAxios } from "../contexts/AxiosProvider";
import Button from "./Button";

import { toast, ToastContainer } from "react-toastify";
import Changepasswordmodel from "./popupmodel/Changepasswordmodel";
import { json } from "react-router-dom";

const EditProfile = () => {
  const { getUser, userDetail, setUserDetail } = useAxios();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [walletAddress, setwalletAddress] = useState(null);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedcountry, setselectedcountry] = useState(101);
  const [selectedFile, setSelectedFile] = useState(null);
  const inputRef = useRef(null);
  const { getData, postData, token } = useAxios();
  const [image, setImage] = useState(null);
  const handleChange = (e) => {
    if (e.target.name === "country_id") {
      setselectedcountry(e.target.value);
    }

    if (e.target.name === "wallet_address") {
      var list = JSON.parse(userDetail?.wallet_address) ?? [];

      var data = {
        type: "Bep20WalletAddress",
        wallet: e.target.value,
      };
      var flag = false;

      for (let i = 0; i < list.length; i++) {
        //console.log(list[i].type);
        if (list[i].type == "Bep20WalletAddress") {
          list[i].wallet = e.target.value;
          flag = false;
        } else {
          flag = true;
        }
      }
      if (flag) {
        list.push(data);
      }

      //console.log({ ...userDetail, [e.target.name]: JSON.stringify(list) });
      setUserDetail({ ...userDetail, [e.target.name]: JSON.stringify(list) });
      setwalletAddress(e.target.value);
    } else {
      setUserDetail({ ...userDetail, [e.target.name]: e.target.value });
    }
  };
  const fileChange = async (e) => {
    let file = e.target.files[0];
    let fileType = file.type; // image/jpeg
    let fileSize = file.size; // 1MB

    if (fileSize > 1 * 1000000) {
      // fileSize > 1MB then show popup message
      alert(
        `File size is too large, please upload image of size less than 1MB.\nSelected File Size: ${
          fileSize / 1000000
        }MB only`
      );
      // return;
    } else {
      setImage(URL.createObjectURL(e.target.files[0]));
      setSelectedFile(e.target.files[0]);
      await addImage(e.target.files[0]);
      // postData("/api/v1/user/update_image", {image:e.target.files[0]}, token == null ? {} : {
      //     headers: { Authorization: `Bearer ${token}` },
      // }).then((res) => {
      //
      // })
      // addImage()
    }
  };

  function handleCopy() {
    if (inputRef.current) {
      navigator.clipboard.writeText(inputRef.current.value);
      toast.success("copied", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const getCountries = async () => {
    try {
      const res = await getData(
        "/api/v1/getCountry",
        token == null
          ? {}
          : {
              headers: { Authorization: `Bearer ${token}` },
            }
      );

      setCountries(res?.data?.data);
    } catch (error) {}
  };

  const getStates = async (id) => {
    try {
      const res = await postData(
        "/api/v1/getState",
        { country_id: id },
        token == null
          ? {}
          : {
              headers: { Authorization: `Bearer ${token}` },
            }
      );

      setStates(res?.data?.data);
    } catch (error) {}
  };

  const updateProfile = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();
      const res = await postData(
        "/api/v1/user/updateProfile",
        userDetail,
        token == null
          ? {}
          : {
              headers: { Authorization: `Bearer ${token}` },
            }
      );
      //  setUserDetail(res?.data?.date?.userDetail)

      toast.success(res?.data?.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {}
    setLoading(false);
  };

  const addImage = async (e) => {
    // e.preventDefault();

    const formData = new FormData();
    formData.append("image", e);

    await postData(
      "/api/v1/user/update_image",
      formData,
      token == null
        ? {}
        : {
            headers: { Authorization: `Bearer ${token}` },
          }
    ).then((res) => {
      toast.success(res?.data?.msg);
    });
  };

  useEffect(() => {
    async function fetch() {
      // await getUser().then(async (res) => {
      //console.log(userDetail?.wallet_address);
      var list =
        userDetail?.wallet_address != null
          ? JSON.parse(userDetail?.wallet_address)
          : [];

      for (let i = 0; i < list.length; i++) {
        //console.log(list[i].type);
        if (list[i].type == "Bep20WalletAddress") {
          setwalletAddress(list[i].wallet);
        } else {
        }
      }
      //console.log("fetch");
      // var wallet = (JSON.parse(userDetail?.wallet_address ?? [])?.find(
      //   (person) => person.type === "Bep20WalletAddress"
      // )).wallet;
      // setwalletAddress(wallet);
      await getStates(userDetail?.country_id ?? 101).then((e) => {
        setselectedcountry(userDetail?.country_id ?? 101);
      });
      // });
      await getCountries();
    }
    fetch();
    // setwalletAddress(userDetail?.wallet_address ?? []);
  }, [userDetail]);
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, "0");
  const day = today.getDate().toString().padStart(2, "0");
  const todayString = `${year}-${month}-${day}`;
  return (
    <div className="flex justify-center p-3 align-middle">
      <div>
        <div
          className="p-4    shadow-md text-black dark:text-white
         bg-white dark:bg-slate-800"
        >
          <div className="">
            <span className="text-xl font-semibold block">User Profile</span>
          </div>
          <div className=" pt-6 pb-3 mx-2 flex  justify-center ">
            <img
              id="showImage"
              className="h-32 w-32 items-center border rounded-full"
              src={image ?? userDetail?.avatar}
              alt=""
            />
            <div className="flex relative">
              <FaRegEdit />
              <input
                type="file"
                accept="image/png, image/jpeg,image/jpg, image/webp"
                name="image"
                onChange={(e) => {
                  fileChange(e);
                }}
                className="absolute cursor-pointer w-8 h-8 top-0 opacity-0"
              />
            </div>
          </div>
          <div className=" p-2 mx-2 flex  justify-center ">
            <h2> {userDetail?.name}</h2>
          </div>

          <Button onClick={() => setShowModal(true)}>Change Password</Button>
        </div>
        <form
          onSubmit={updateProfile}
          className="p-8  shadow-md text-black dark:text-white bg-white
           dark:bg-slate-800"
        >
          <div className="rounded  shadow p-6">
            <div className="flex justify-between editinput">
              <div className="pb-4 ">
                <label
                  htmlFor="fullname"
                  className="font-semibold  block pb-1 text-black dark:text-white bg-white dark:bg-slate-800"
                >
                  Full Name
                </label>
                <div className="flex">
                  <input
                    id="name"
                    placeholder="full name"
                    name="name"
                    maxLength={25}
                    value={userDetail?.name ?? ""}
                    onChange={(e) => handleChange(e)}
                    className="border-1 border-slate-500 text-sm rounded-xl px-4 py-1 max-w-56 text-gray-900
                     dark:text-white bg-gray-50  dark:bg-slate-800 inputfieldwidth sm:mr-3"
                    type="text"
                  />
                </div>
              </div>
              <div className="pb-5">
                <label
                  htmlFor="username"
                  className="font-semibold block pb-1 font-xs text-black  dark:text-white bg-white dark:bg-slate-800"
                >
                  User Name
                </label>
                <div className="flex">
                  <input
                    id="username"
                    name="username"
                    value={userDetail?.username ?? ""}
                    onChange={handleChange}
                    placeholder="user name"
                    className="border-1 border-slate-500 text-sm  rounded-xl px-4 py-1 max-w-56 text-gray-900
                     dark:text-white bg-gray-50  dark:bg-slate-800 inputfieldwidth"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="pb-5">
              <label className="font-semibold mr-3 pb-1 text-black dark:text-white bg-white dark:bg-slate-800">
                Gender:
              </label>
              <input
                type="radio"
                name="gender"
                value="MALE"
                className="mx-1 "
                checked={userDetail?.gender === "MALE"}
                onChange={handleChange}
              />{" "}
              <span className="text-xs">Male</span>
              <input
                type="radio"
                name="gender"
                value="FEMALE"
                className="mx-1"
                checked={userDetail?.gender === "FEMALE"}
                onChange={handleChange}
              />
              <span className="text-xs">Female</span>
            </div>
            <div className="pb-6 editinput place">
              <select
                disabled={userDetail?.is_mobile_verified == 1}
                name="country_id"
                value={selectedcountry ?? ""}
                defaultValue={selectedcountry}
                className="border-1 text-sm border-slate-500 rounded-xl font-xs px-2 py-1 max-w-28 mt-3
                             mr-2 text-gray-900 dark:text-white bg-gray-50  dark:bg-slate-800 inputfieldwidth 
                             placeinput sm:mr-2"
                onChange={(e) => {
                  getStates(e.target.value);
                  handleChange(e);
                }}
              >
                {countries?.map((country) => (
                  <option
                    key={country?.id}
                    value={country?.id}
                    selected={selectedcountry}
                  >
                    {country?.name}
                  </option>
                ))}
              </select>
              <select
                name="state_id"
                value={userDetail?.state_id}
                defaultValue={userDetail?.state_id}
                className="border-1 text-sm border-slate-500 rounded-xl px-2 py-1 max-w-28 font-xs mt-3
                             text-gray-900 dark:text-white bg-gray-50  dark:bg-slate-800 inputfieldwidth placeinput sm:mr-2"
                onChange={(e) => handleChange(e)}
              >
                {states?.map((state) => (
                  <option
                    key={state?.id}
                    value={state?.id}
                    selected={userDetail?.state_id}
                  >
                    {state?.name}{" "}
                  </option>
                ))}
              </select>
              <input
                type="text"
                name="city"
                placeholder="City"
                className="text-sm border-1 rounded-xl px-2 py-1 max-w-28 mt-3
                             font-xs border-slate-500 text-gray-900 dark:text-white bg-gray-50
                               dark:bg-slate-800 inputfieldwidth placeinput sm:mr-2"
                value={userDetail?.city}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <label>Mobile Number</label>{" "}
            <div className="pb-6 flex justify-start  editinput">
              <input
                type="number"
                value={userDetail?.mobile_number ?? ""}
                name="mobile_number"
                // readOnly  ={userDetail?.is_mobile_verified == 1}
                disabled={userDetail?.is_mobile_verified == 1}
                onChange={handleChange}
                className={
                  (userDetail?.is_mobile_verified?.toString() === "1"
                    ? " text-gray-600 dark:text-gray-400 "
                    : " ") +
                  "border-1 rounded-xl max-w-60 border-slate-500  text-sm px-2 py-1 mr-2  bg-gray-50  dark:bg-slate-800 inputfieldwidth"
                }
                // disabled={true}
              />

              {/* <p className='mx-4 border-l-1 text-sm  '>1324567890</p> */}

              <button
                type="button"
                disabled={userDetail?.is_mobile_verified == 1}
                className=" rounded-lg bg-kgolden py-1 px-2 w-18 margins  text-xs font-bold uppercase text-white shadow-md
                            "
              >
                {userDetail?.is_mobile_verified == 1 ? (
                  <p> verified </p>
                ) : (
                  <p> verify</p>
                )}
              </button>
            </div>
            <div className="pb-6 flex justify-start  editinput">
              <input
                type="text"
                name="email"
                disabled={userDetail?.is_email_verified == 1}
                value={userDetail?.email}
                onChange={handleChange}
                className={
                  (userDetail?.is_email_verified?.toString() === "1"
                    ? " text-gray-600 dark:text-gray-400 "
                    : "") +
                  "border-1 rounded-xl max-w-60 border-slate-500  text-sm px-2 py-1 mr-2  bg-gray-50  dark:bg-slate-800 inputfieldwidth"
                }
              />

              {/* <FaRegEdit className='text-kgolden  mr-2' /> */}

              <button
                type="button"
                disabled={userDetail?.is_email_verified == 1}
                className="rounded-lg bg-kgolden py-1 px-2 w-18 margins  text-xs font-bold uppercase text-white shadow-md
                "
              >
                {userDetail?.is_email_verified == 1 ? (
                  <p> verified </p>
                ) : (
                  <p> verify</p>
                )}
              </button>
            </div>
            <div className="pb-6 editinput">
              <input
                type="date"
                name="dob"
                max={todayString}
                className="border-1 rounded-xl max-w-44 text-sm px-2 border-slate-500 py-1 text-gray-900
                             dark:text-white bg-gray-50  dark:bg-slate-800 sm:mr-3 inputfieldwidth"
                value={userDetail?.dob}
                onChange={handleChange}
              />
              <input
                type="text"
                placeholder="address"
                className="border-1 rounded-xl max-w-44 text-sm px-2 py-1 border-slate-500
                              text-gray-900 dark:text-white bg-gray-50  dark:bg-slate-800 inputfieldwidth margins"
                name="address"
                value={userDetail?.address}
                onChange={handleChange}
              />
            </div>
            <div className="pb-6 flex justify-start editinput">
              <input
                type="number"
                placeholder="pincode"
                className="border-1 rounded-xl max-w-44 text-sm px-2 py-1
                             border-slate-500 text-gray-900 dark:text-white bg-gray-50
                               dark:bg-slate-800 inputfieldwidth sm:mr-3"
                name="pincode"
                value={userDetail?.pincode}
                onChange={handleChange}
              />

              <div
                className="flex justify-start items-center border-1 border-slate-500 
               rounded-xl max-w-48 text-gray-900 dark:text-white bg-gray-50  dark:bg-slate-800
                inputfieldwidth margins "
              >
                <input
                  type="text"
                  className="max-w-36 mx-2 text-sm select-none	 inputfieldwidth"
                  ref={inputRef}
                  disabled={true}
                  value={userDetail?.referral_code}
                  // onChange={handleChange}
                />{" "}
                <IoCopy onClick={handleCopy} className="mr-2" />
              </div>
            </div>
            <input
              type="text"
              placeholder="BEP20 Wallet Address"
              className="border-1 rounded-xl max-w-44 text-sm px-2 py-1
                             border-slate-500 text-gray-900 dark:text-white bg-gray-50
                               dark:bg-slate-800 inputfieldwidth sm:mr-3"
              name="wallet_address"
              value={walletAddress}
              onChange={handleChange}
            />
            <div className="flex justify-center">
              {loading ? (
                <Button text={"Loading..."} type="button" />
              ) : (
                <Button text={"Update"} type="submit" />
              )}
            </div>
          </div>
          {/* </div> */}
          <ToastContainer />
        </form>
      </div>
      <Changepasswordmodel showModal={showModal} setShowModal={setShowModal} />
      {/* <EditProfileModal showModal={showModal} setShowModal={setShowModal} /> */}
    </div>
  );
};

export default EditProfile;
