import React, { useEffect, useState } from "react";
import { useAxios } from "../contexts/AxiosProvider";

import { BiCopy, BiPlus } from "react-icons/bi";
import { FaSync } from "react-icons/fa";

import { SiBlockchaindotcom } from "react-icons/si";
import { RiExchangeDollarLine } from "react-icons/ri";

import { TbEdit } from "react-icons/tb";
import { MdDelete } from "react-icons/md";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import BlockchainModal from "./BlockchainModal";
import { useNavigate } from "react-router-dom";
import ExchangeModal from "./ExchangeModal";
import { CoinImage } from "./coinimage";
import { toast, ToastContainer } from "react-toastify";
import EditconnectionnameModel from "./EditconnectionnameModel";

import { Button } from "@mui/material";

const AddBlockExcOnProfile = () => {
  // const [isClicked, setIsClicked] = useState(false);
  // const [isDelete, setIsDelete] = useState(false);
  // const [index, setIndex] = useState(0);
  const [selectedkey, setselectedkey] = useState("");
  const [search, setSearch] = useState("");
  const [hodl, sethodl] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [showeditModal, setshoweditModal] = useState(false);
  const [uniqueid, setuniqueid] = useState(null);

  const [connectionname, setconnectionname] = useState();
  const [showExchange, setShowExchange] = useState(false);
  const {
    getBlockchains,
    getExchanges,
    loading,
    userReUpdateExchangeChangeByWalletId,
    blockchains,
    syncflag,
    exchanges,
    setsyncflag,
    userReUpdateBlockchainByWalletId,
    userDeletePortfolio,
    getUserBlockchainAndExchangeapi,
    userDeletePortfolioByWalletAddress,
    getUserBlockchainAndExchange,
  } = useAxios();

  const [showBlock, setShowBlock] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deletebywallet, setdeletebywallet] = useState(true);
  const [allrefresh, setallrefresh] = useState(false);
  const navigate = useNavigate();

  // const plus = (ind) => {
  //   setIndex(ind);
  //   setIsClicked(true);
  // };
  // const minus = (ind) => {
  //   setIndex(ind);
  //   setIsClicked(false);
  // };

  const BlockchaintableRow = ["#", "Blockchain", "Connection Name", "Action"];
  const ExchangetableRow = [
    "#",
    "Exchange",
    "Connection Name",
    "Api Key",
    "Action",
  ];

  const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  // return new Promise((res) => setTimeout(res, delay));

  useEffect(() => {
    async function load() {
      await getBlockchains();
      await getExchanges();
      await getUserBlockchainAndExchangeapi();
    }
    load();
  }, []);

  async function blockchainsync(e) {
    setallrefresh(true);

    await Promise.all(
      Object.entries(e?.blockchain_list ?? {})?.map(([key, val]) =>
        val?.map(
          (holding, ind) =>
            new Promise(async (resolve, reject) => {
              var res = await userReUpdateBlockchainByWalletId(
                holding?.unique_id
              );
              resolve(res);
            })
        )
      )
    ).then((values) => {
      return true;
    });

    return true;
  }
  async function exchangesync(e) {
    setallrefresh(true);
    await Promise.all(
      e?.exchange_list?.map(
        (holding, ind) =>
          new Promise(async (resolve, reject) => {
            var res = await userReUpdateExchangeChangeByWalletId(
              holding?.unique_id
            );
            resolve(res);
          })
      )
    ).then((values) => {
      return true;
    });

    return true;
  }

  const buttoncss1 = `flex justify-center  rounded-lg  py-3 px-6 font-sans text-xs font-bold uppercase
text-white shadow-md  transition-all hover:shadow-lg focus:shadow-none `;
  return (
    <div className=" text-black dark:text-white">
      <div className="w-full px-6 mx-auto ">
        <div className="w-full px-4 mt-2">
          <h1
            className="
                  font-medium
                  text-4xl
                  my-4
                  text-center
                  text-black dark:text-white
                "
          >
            Manage Your Portfolio
          </h1>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 gap-2 my-8 ">
          <button
            className={buttoncss1 + (!showBlock ? "bg-gray-400" : "bg-kgolden")}
            data-ripple-light="true"
            onClick={() => {
              setShowBlock(true);
            }}
          >
            <SiBlockchaindotcom
              size="18px"
              className="mr-2"
            ></SiBlockchaindotcom>{" "}
            Blockchain
          </button>
          <button
            className={buttoncss1 + (showBlock ? "bg-gray-400" : "bg-kgolden")}
            data-ripple-light="true"
            onClick={() => {
              setShowBlock(false);
            }}
          >
            <RiExchangeDollarLine
              size="18px"
              className="mr-2"
            ></RiExchangeDollarLine>{" "}
            Exchange
          </button>

          <button
            className="flex justify-center px-4 py-1 rounded-xl border-2 items-center text-black dark:text-white dark:bg-slate-800"
            onClick={() => {
              if (showBlock) {
                setShowModal(true);
              } else {
                setShowExchange(true);
              }
            }}
          >
            <span className="mx-1 rounded-full bg-kgolden text-xl p-1">
              <BiPlus color="white" />
            </span>
            Add
            {showBlock ? " Blockchain" : " Exchange"}
          </button>
        </div>

        <div className="p-0  overflow-x-auto w-full">
          {showBlock ? (
            <div className="my-3 mx-1 flex justify-between ">
              <Button
                className="mr-4"
                onClick={
                  allrefresh
                    ? null
                    : async () => {
                        await blockchainsync(getUserBlockchainAndExchange).then(
                          async (e) => {
                            await getBlockchains();
                            toast.success("Portfolio updated successfully");
                            setallrefresh(false);
                          }
                        );
                      }
                }
                variant="outlined"
              >
                <p className="mr-2">Sync</p>
                <FaSync
                  className={
                    " dark:fill-white  fill:black " +
                    (allrefresh ? "animate-spin" : "")
                  }
                />
              </Button>
              <input
                type="text"
                placeholder="search"
                className="float-right ml-3 px-5 py-1
              text-sm text-gray-900 dark:text-white border w-2/5 border-gray-300 rounded-lg
               bg-gray-50  dark:bg-slate-800"
                value={search}
                onChange={(e) => {
                  if (search != null && search?.length > 1) {
                    var myMap = {};

                    for (var key in getUserBlockchainAndExchange?.blockchain_list ??
                      {}) {
                      if (
                        key
                          .toLowerCase()
                          .startsWith(e.target.value.toLowerCase())
                      ) {
                        myMap[key] =
                          getUserBlockchainAndExchange?.blockchain_list[key];
                      }
                    }

                    sethodl(myMap);
                  } else {
                    sethodl(getUserBlockchainAndExchange?.blockchain_list);
                  }
                  setSearch(e?.target?.value);
                }}
              />
            </div>
          ) : null}

          {!showBlock ? (
            <div className="flex justify-start">
              <Button
                className="mr-4"
                onClick={
                  allrefresh
                    ? null
                    : async () => {
                        await exchangesync(getUserBlockchainAndExchange).then(
                          async (e) => {
                            await getExchanges();
                            toast.success("Portfolio updated successfully");
                            setallrefresh(false);
                          }
                        );
                      }
                }
                variant="outlined"
              >
                <p className="mr-2">Sync</p>
                <FaSync
                  className={
                    " dark:fill-white  fill:black " +
                    (allrefresh ? "animate-spin" : "")
                  }
                />
              </Button>
            </div>
          ) : null}
          <table className=" mb-0 w-full  border-gray-200 text-black dark:text-white">
            <thead>
              <tr>
                {(!showBlock ? ExchangetableRow : BlockchaintableRow)?.map(
                  (val, i) => (
                    <th
                      key={i + val}
                      className={
                        "py-3 font-bold uppercase border-b border-gray-200   " +
                        (i < 2 ? "text-left" : "text-right")
                      }
                    >
                      {val}
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr key={"test"}>
                  <td colSpan="6">
                    <div className="flex items-center justify-center text-center">
                      <div
                        className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full mx-auto text-center"
                        role="status"
                      ></div>
                    </div>
                  </td>
                </tr>
              ) : (
                  showBlock
                    ? getUserBlockchainAndExchange?.blockchain_list?.length ===
                      0
                    : getUserBlockchainAndExchange?.exchange_list?.length === 0
                ) ? (
                <tr key={"No record found"}>
                  <td colSpan="6 ">
                    <div className="flex items-center h-14 border-b  justify-center ">
                      No record found
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {showBlock
                    ? Object.entries(
                        hodl ??
                          getUserBlockchainAndExchange?.blockchain_list ??
                          {}
                      )?.map(([key, val]) => (
                        <React.Fragment key={key}>
                          <tr
                            className="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent"
                            key={key + val[0]?.connection_name}
                          >
                            <td className="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                              {selectedkey !== key ? (
                                <AiOutlinePlusCircle
                                  size="24px"
                                  onClick={() => {
                                    setselectedkey(key);
                                  }}
                                />
                              ) : (
                                <AiOutlineMinusCircle
                                  size="24px"
                                  className="hover:color-kgolden"
                                  onClick={() => setselectedkey(null)}
                                />
                              )}
                            </td>
                            <td className="p-2  w-min text-start  align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                              <div
                                className={
                                  key?.length > 50
                                    ? "items-start align-middle"
                                    : "flex "
                                }
                                onClick={() => {
                                  if (window.isSecureContext) {
                                    navigator.clipboard.writeText(key);
                                    toast.success("Copied");
                                  }
                                }}
                              >
                                <p className=" mb-0 font-semibold flex align-middle items-center  text-s mr-2">
                                  {key.substring(0, 49)}
                                </p>
                                <p className=" mb-0 font-semibold flex align-middle items-center  text-s mr-2">
                                  {key.substring(49)}
                                  {window.isSecureContext ? <BiCopy /> : null}
                                </p>
                              </div>
                            </td>
                            {/* <td className="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent"></td> */}
                            <td className="p-2 text-center  align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                              {/* {val[0]?.connection_name} */}
                              <div
                                className="flex tems-end justify-end "
                                onClick={() => {
                                  setshoweditModal(true);
                                  setconnectionname(val[0]?.connection_name);
                                  setuniqueid(val[0]?.unique_id);
                                }}
                              >
                                <p className="mb-0 font-semibold leading-tight text-xs mr-2">
                                  {val[0]?.connection_name?.length > 20
                                    ? val[0]?.connection_name?.substring(
                                        0,
                                        20
                                      ) + "..."
                                    : val[0]?.connection_name}
                                </p>

                                <TbEdit />
                              </div>
                            </td>
                            <td className="py-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                              <div className="flex justify-end">
                                <button
                                  className="text-white bg-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                                  data-ripple-light="true"
                                  onClick={() => {
                                    setdeletebywallet(true);
                                    setConfirmDelete(true);
                                    setuniqueid(key);
                                  }}
                                >
                                  <MdDelete size="18px"></MdDelete>
                                </button>
                              </div>
                            </td>
                          </tr>

                          {selectedkey === key
                            ? val?.map((holding, ind) => {
                                return (
                                  <tr key={holding?.unique_id + "te" + ind}>
                                    <td className="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                      <p className="pl-2"> {ind + 1}</p>
                                    </td>
                                    <td className="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                      <div className="flex align-middle justify-start items-center">
                                        <CoinImage
                                          image={holding?.image}
                                          alt={holding?.name}
                                          loading="lazy"
                                          className="px-1 mx-1  h-5 rounded-full"
                                        />
                                        <h2> {holding?.name}</h2>
                                        <Button
                                          variant="text"
                                          size="small"
                                          onClick={() => {
                                            navigate(
                                              `/manage-portfolio/coinlist/${holding.unique_id}`,
                                              {
                                                state: {
                                                  unique_id: holding.unique_id,
                                                },
                                              }
                                            );
                                          }}
                                        >
                                          View Token
                                        </Button>
                                      </div>
                                    </td>

                                    <td className="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                      {/*    <div
                                        className="flex self-center"
                                        onClick={() => {
                                          setshoweditModal(true);
                                          setconnectionname(
                                            holding?.connection_name
                                          );
                                          setuniqueid(holding?.unique_id);
                                        }}
                                      >
                                        <p className="mb-0 font-semibold leading-tight text-xs mr-2">
                                          {holding?.connection_name?.length > 20
                                            ? holding?.connection_name?.substring(
                                                0,
                                                20
                                              ) + "..."
                                            : holding?.connection_name}
                                        </p>

                                        <TbEdit />
                                      </div> */}
                                    </td>
                                    {/*     <td className="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                      <div
                                        className=" flex"
                                        onClick={() => {
                                          if (window.isSecureContext) {
                                            navigator.clipboard.writeText(
                                              holding?.api_key
                                            );
                                            toast.success("Copied");
                                          }
                                        }}
                                      >
                                        {" "}
                                        <p className="mb-0 font-semibold leading-tight text-xs mr-2">
                                          {holding?.api_key}
                                        </p>
                                        {window.isSecureContext ? (
                                          <BiCopy />
                                        ) : null}
                                      </div>
                                    </td> */}
                                    <td className="p-2 flex justify-end align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                      <div className="flex">
                                        <Button
                                          className="mr-2 "
                                          onClick={async () => {
                                            var pro = new Promise(
                                              async (resolve, reject) => {
                                                var res =
                                                  await userReUpdateBlockchainByWalletId(
                                                    holding?.unique_id
                                                  );
                                                resolve(true);
                                              }
                                            );
                                            pro.then(async (e) => {
                                              await getUserBlockchainAndExchangeapi();
                                              toast.success(
                                                "Portfolio updated successfully"
                                              );
                                              setallrefresh(false);
                                            });
                                          }}
                                          variant="outlined"
                                        >
                                          <FaSync
                                            id={ind}
                                            className={
                                              " dark:fill-white  fill:black " +
                                              (syncflag.includes(
                                                holding?.unique_id
                                              )
                                                ? "animate-spin"
                                                : "")
                                            }
                                          />
                                        </Button>
                                        <div className="mr-2"></div>
                                        <button
                                          className="text-white  bg-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex  px-5 py-2.5 text-center mr-2"
                                          data-ripple-light="true"
                                          onClick={() => {
                                            setdeletebywallet(false);
                                            setConfirmDelete(true);
                                            setuniqueid(holding?.unique_id);
                                          }}
                                        >
                                          <MdDelete></MdDelete>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                                /*  <tr>
                                  <td className="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <p className="pl-2"> {ind + 1}</p>
                                  </td>
                                  <td className="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <div className="flex justify-start text-center pl-2">
                                      <CoinImage
                                        image={holding?.image}
                                        alt="img"
                                        loading="lazy"
                                        className="px-1 mx-1  h-5 rounded-full"
                                      />
                                      <h2> {holding?.name}</h2>
                                    </div>
                                  </td>

                                  <td className="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <div
                                      className="flex self-center"
                                      onClick={() => {
                                        setshoweditModal(true);
                                        setconnectionname(
                                          holding?.connection_name
                                        );
                                        setuniqueid(holding?.unique_id);
                                      }}
                                    >
                                      <p className="mb-0 font-semibold leading-tight text-xs mr-2">
                                        {holding?.connection_name?.length > 20
                                          ? holding?.connection_name?.substring(
                                              0,
                                              20
                                            ) + "..."
                                          : holding?.connection_name}
                                      </p>

                                      <TbEdit />
                                    </div>
                                  </td>
                                  <td className="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <div
                                      className=" flex"
                                      onClick={() => {
                                        if (window.isSecureContext) {
                                          navigator.clipboard.writeText(
                                            holding?.api_key
                                          );
                                          toast.success("Copied");
                                        }
                                      }}
                                    >
                                      {" "}
                                      <p className="mb-0 font-semibold leading-tight text-xs mr-2">
                                        {holding?.api_key}
                                      </p>
                                      {window.isSecureContext ? (
                                        <BiCopy />
                                      ) : null}
                                    </div>
                                  </td>
                                  <td className="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <button
                                      className="text-white bg-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                                      data-ripple-light="true"
                                      onClick={() => {
                                        setConfirmDelete(true);
                                        setuniqueid(holding?.unique_id);
                                      }}
                                    >
                                      Remove
                                    </button>
                                  </td>
                                </tr> */
                              })
                            : null}
                        </React.Fragment>
                      ))
                    : getUserBlockchainAndExchange?.exchange_list?.map(
                        (holding, ind) => (
                          <tr
                            key={holding?.api_key + ind + 1}
                            className="py-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent"
                          >
                            <td className="py-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                              <p> {ind + 1}</p>
                            </td>
                            <td className="py-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                              <div className="flex justify-start text-center pl-2">
                                <CoinImage
                                  image={holding?.image}
                                  alt="img"
                                  loading="lazy"
                                  className="px-1 mx-1  h-5 rounded-full"
                                />
                                <h2> {holding?.name}</h2>
                              </div>
                            </td>

                            <td className="py-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                              <div
                                className="flex self-center justify-end"
                                onClick={() => {
                                  setshoweditModal(true);
                                  setconnectionname(holding?.connection_name);
                                  setuniqueid(holding?.unique_id);
                                }}
                              >
                                <p className="mb-0 font-semibold leading-tight text-xs mr-2">
                                  {holding?.connection_name?.length > 20
                                    ? holding?.connection_name?.substring(
                                        0,
                                        20
                                      ) + "..."
                                    : holding?.connection_name}
                                </p>

                                <TbEdit />
                              </div>
                            </td>
                            <td className="py-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                              <div
                                className=" flex justify-end"
                                onClick={() => {
                                  if (window.isSecureContext) {
                                    navigator.clipboard.writeText(
                                      holding?.api_key
                                    );
                                    toast.success("Copied");
                                  }
                                }}
                              >
                                {" "}
                                <p className="mb-0 font-semibold leading-tight text-xs mr-2">
                                  {holding?.api_key}
                                </p>
                                {window.isSecureContext ? <BiCopy /> : null}
                              </div>
                            </td>
                            <td className="py-2  text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                              <div className="flex justify-end">
                                <Button
                                  variant="outlined"
                                  size="small"
                                  onClick={() => {
                                    navigate(
                                      `/manage-portfolio/coinlist/${holding.unique_id}`,
                                      {
                                        state: {
                                          unique_id: holding.unique_id,
                                        },
                                      }
                                    );
                                  }}
                                >
                                  View Token
                                </Button>
                                <div className="mr-2  "></div>

                                <Button
                                  className="mr-4"
                                  onClick={async () => {
                                    var pro = new Promise(
                                      async (resolve, reject) => {
                                        var res =
                                          await userReUpdateExchangeChangeByWalletId(
                                            holding?.unique_id
                                          );
                                        resolve(true);
                                      }
                                    );
                                    pro.then(async (e) => {
                                      // await getUserBlockchainAndExchangeapi();
                                      toast.success(
                                        "Portfolio updated successfully"
                                      );
                                    });
                                  }}
                                  variant="outlined"
                                >
                                  <FaSync
                                    className={
                                      " dark:fill-white  fill:black " +
                                      (syncflag.includes(holding?.unique_id)
                                        ? "animate-spin"
                                        : "")
                                    }
                                  />
                                </Button>
                                <div className="mr-2  "></div>
                                <button
                                  className="text-white bg-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                                  data-ripple-light="true"
                                  onClick={() => {
                                    setdeletebywallet(false);
                                    setConfirmDelete(true);
                                    setuniqueid(holding?.unique_id);
                                  }}
                                >
                                  <MdDelete></MdDelete>
                                </button>
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                </>
              )}
            </tbody>
          </table>
        </div>
        {confirmDelete && (
          <div className=" flex justify-center items-center  fixed delete-modal  z-50  p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] ">
            <div className="relative w-full  max-w-md h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <button
                  type="button"
                  className="absolute top-3 right-3.5 text-gray-400  hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                  data-modal-hide="popup-modal"
                  onClick={() => setConfirmDelete(false)}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="p-6 text-center">
                  <svg
                    aria-hidden="true"
                    className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-white"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-white">
                    Are you sure you want to delete this ?
                  </h3>
                  <button
                    data-modal-hide="popup-modal"
                    type="button"
                    onClick={async () => {
                      if (!deletebywallet) await userDeletePortfolio(uniqueid);
                      else await userDeletePortfolioByWalletAddress(uniqueid);
                      setConfirmDelete(false);
                    }}
                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                  >
                    Yes, I'm sure
                  </button>
                  <button
                    data-modal-hide="popup-modal"
                    type="button"
                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-white dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    onClick={() => setConfirmDelete(false)}
                  >
                    No, cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <EditconnectionnameModel
          showeditModal={showeditModal}
          setshoweditModal={setshoweditModal}
          connectionname={connectionname}
          unique_id={uniqueid}
        />
        <BlockchainModal
          showModal={showModal}
          setShowModal={setShowModal}
          blockchains={blockchains}
        />
        <ExchangeModal
          showExchange={showExchange}
          setShowExchange={setShowExchange}
          exchanges={exchanges}
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddBlockExcOnProfile;
