import React from "react";

export default function Coinimage({ holding }) {
  return (
    <img
      src={
        holding?.logo?.length > 50
          ? holding?.logo //coin/jassmy.png //""
          : "https://d3iuzwoiyg9qa8.cloudfront.net/webadmin/storage/public" +
            holding?.logo
      }
      // src={holding?.logo}
      alt="img"
      loading="lazy"
      className="px-1 mx-1  h-5 rounded-full"
    />
  );
}

export function CoinImage({ image }) {
  return (
    <img
      src={
        image?.length > 50
          ? image //coin/jassmy.png //""
          : "https://d3iuzwoiyg9qa8.cloudfront.net/webadmin/storage/public" +
            image
      }
      alt="img"
      loading="lazy"
      className="px-1 mx-1  h-5 rounded-full"
    />
  );
}
