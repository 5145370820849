import * as Yup from "yup";

export const connectExchangeSchema = Yup.object({
  // connection_name: Yup.string().required("Please enter Exchangee"),
  api_key: Yup.string().required("Please enter your API key"),
  secret: Yup.string().required("Please enter your secret key"),
  passphrase: Yup.string().required("Please enter your Passphrase"),
});
export const connectExchangeSchemanotRequired = Yup.object({
  // connection_name: Yup.string().required("Please enter Exchangee"),
  api_key: Yup.string().required("Please enter your API key"),
  secret: Yup.string().required("Please enter your secret key"),
  // passphrase: Yup.string().notRequired("Please enter your Passphrase"),
});
