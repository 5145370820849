import React from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import "./toggle.css";
export default function Switchthemetoggle() {
  const { currentMode, setMode } = useStateContext();
  return (
    <>
      <div className="toggleWrapper pb-2">
        <input
          type="checkbox"
          value={currentMode}
          defaultChecked={currentMode === "Dark" ? true : false}
          className="dn"
          id="dn"
          aria-label="Search"
          onClick={setMode}
        />
        <label htmlFor="dn" className="toggle">
          <span className="toggle__handler">
            <span className="crater crater--1"></span>
            <span className="crater crater--2"></span>
            <span className="crater crater--3"></span>
          </span>
          <span className="star star--1"></span>
          <span className="star star--2"></span>
          <span className="star star--3"></span>
          <span className="star star--4"></span>
          <span className="star star--5"></span>
          <span className="star star--6"></span>
        </label>
      </div>
    </>
  );
}
