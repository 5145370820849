import React, { useEffect } from "react";
// import india from "./in.png";
export const TaxCalculator = (props) => {
  useEffect(() => {
    window.location.replace("https://www.coingabbar.com/en/crypto-tax-tools");
  }, []);

  const list = [
    { name: "webb1czxczc", url: "https://www.coingabbar.com" },
    { name: "webb2zxcz", url: "https://www.coingabbar.com" },
    { name: "webb3zxczc", url: "https://www.coingabbar.com" },
    { name: "webb4zcz", url: "https://www.coingabbar.com" },
  ];
  return (
    <div></div>
    /*  <div className="  dark:text-white text-black ">
      <div
        className=" 
                hero-content
                mx-8
                wow
                p-3
                shadow-xl 
                rounded-2xl
                my-6
              bg-white dark:bg-slate-800
                fadeIn
              "
        data-wow-delay=".2s"
      >
        <h1 className="flex justify-center text-2xl mb-4">Crypto Tax Tools</h1>
        <h2 className="flex justify-center mb-4">
          Calculating your crypto taxes can be confusing at times and that is
          why CoinGabbar brings you the best crypto tax calculation tools. These
          tools are designed to simplify the process, ensuring compliance with
          the evolving tax regulations.
        </h2>
        <p className="flex justify-center font-bold">
          Choose your Destination :
        </p>

        <div className=" grid px-5 py-2   cryptotaxt place  ">
          {list.map((e) => {
            return (
              <div className="px-2 border flex justify-center items-center rounded-md m-1 ">
                <img src={india} className="rounded-full w-5 h-5 " alt="flag" />
                <a
                  className="p-2  m-4 text-start flex justify-center items-center"
                  href={e.url}
                  target="_blank"
                >
                  <p>
                    Binocs is a Simplest Crypto Tax Software And Accounting
                    Platform. Designed for individuals, institutions and
                    enterprises. Calculate your crypto taxes & track portfolios
                    across 300+ Exchanges, Wallets and chains 📊💸
                  </p>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div> */
  );
};

export default TaxCalculator;
