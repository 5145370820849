import React, { useState } from "react";
import { useAxios } from "../../contexts/AxiosProvider";

import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";

import { Dialog } from "@mui/material";
import Button from "@mui/material/Button";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useStateContext } from "../../contexts/ContextProvider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Regforminput from "../registrationform/Regforminput";

const Changepasswordmodel = ({ showModal, setShowModal }) => {
  const [isoldPassword, setisoldPassword] = useState(false);
  const [isPassword, setisPassword] = useState(false);
  const [isConfirmPass, setisConfirmPass] = useState(false);
  const [loading, setisloading] = useState(false);

  const [values, setValues] = useState({
    old_password: "",
    password: "",
    password_confirmation: "",
  });
  const { postData } = useAxios();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    {
      let token = localStorage.getItem("token");
      try {
        setisloading(true);
        let res = await postData("/api/v1/user/change_password", values, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success(res?.data?.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        if (res?.status === 200) {
          setTimeout(() => {
            setValues({
              old_password: "",
              password: "",
              password_confirmation: "",
            });
          }, 2000);
          setShowModal(false);
        } else {
        }
      } catch (error) {
        toast.error(error?.response?.data?.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      setisloading(false);
    }
  };
  const inputs = [
    {
      id: 1,
      name: "old_password",
      type: !isoldPassword ? "password" : "text",
      placeholder: "Password",
      errorMessage:
        "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!",
      label: "Password",
      // pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
      click: () => setisoldPassword(!isoldPassword),
    },

    {
      id: 2,
      name: "password",
      type: !isPassword ? "password" : "text",
      placeholder: "Password",
      errorMessage:
        "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!",
      label: "Password",
      pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
      click: () => setisPassword(!isPassword),
    },
    {
      id: 3,
      name: "password_confirmation",
      type: !isConfirmPass ? "password" : "text",
      placeholder: "Confirm Password",
      errorMessage: "Passwords don't match!",
      label: "Confirm Password",
      pattern: values.password,
      required: true,
      click: () => setisConfirmPass(!isConfirmPass),
    },
  ];
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const { currentMode } = useStateContext();

  return (
    <ThemeProvider
      theme={
        currentMode === "Dark"
          ? createTheme({
              palette: {
                mode: "dark",
              },
            })
          : createTheme({
              palette: {
                mode: "light",
              },
            })
      }
    >
      <Dialog
        // fullScreen={fullScreen}
        open={showModal}
        onClose={setShowModal}
        scroll={"paper"}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <div> Change your password</div>
        </DialogTitle>

        <DialogContent>
          <p className="mb-4">
            For added security, your new password should have min. 8 characters
            that contain letters, numbers and symbols.
          </p>
          <div className={currentMode === "Dark" ? "dark" : ""}>
            {inputs?.map((input) => (
              <div className="relative" key={input.id}>
                <Regforminput
                  key={input.id}
                  {...input}
                  checkboxChecked={false}
                  value={values[input.name].toString()}
                  onChange={onChange}
                />
                {input.name === "old_password" && (
                  <span
                    className=" absolute right-3 top-11  cursor-pointer text-white text-xl"
                    onClick={inputs[0].click}
                  >
                    {inputs[0].type === "password" ? (
                      <FaEye className="fill-black dark:fill-white" />
                    ) : (
                      <FaEyeSlash className="fill-black dark:fill-white" />
                    )}
                  </span>
                )}
                {input.name === "password" && (
                  <span
                    className=" absolute right-3 top-11  cursor-pointer text-white text-xl"
                    onClick={inputs[1].click}
                  >
                    {inputs[1].type === "password" ? (
                      <FaEye className="fill-black dark:fill-white" />
                    ) : (
                      <FaEyeSlash className="fill-black dark:fill-white" />
                    )}
                  </span>
                )}
                {input.name === "password_confirmation" && (
                  <span
                    className="absolute right-3 top-11 cursor-pointer text-white text-xl"
                    onClick={inputs[2].click}
                  >
                    {inputs[2].type === "password" ? (
                      <FaEye className="fill-black dark:fill-white" />
                    ) : (
                      <FaEyeSlash className="fill-black dark:fill-white" />
                    )}
                  </span>
                )}{" "}
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          {false ? null : (
            <Button autoFocus onClick={() => setShowModal(false)}>
              cancel
            </Button>
          )}
          <Button
            onClick={async () => {
              handleSubmit();
              //   if (wallet_address === null || wallet_address.trim() === "") {
              //     toast.error("Wallet Address required");
              //   } else if (
              //     selectedOptions === null ||
              //     selectedOptions?.length === 0
              //   ) {
              //     toast.error("Select Alteast one Blockchain ");
              //   } else {
              //     if (!loading) {
              //       await addBlockchiansWithChainName(
              //         connection_name,
              //         wallet_address,
              //         selectedOptions
              //       );
              //     }
              //     setShowModal(false);
              //   }
            }}
            autoFocus
          >
            {loading ? "Loading" : "Update"}
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer />
    </ThemeProvider>
  );
};

export default Changepasswordmodel;
