import axios from "axios";

export const client = axios.create({
  // baseURL :"http://34.131.120.24"
  // baseURL: "https://portfolio.casourabh.com",
  baseURL: "https://portfolio.coingabbar.com",
  // baseURL: "http://192.168.1.83/portfolio_coingabbar",
  // baseURL: "http://192.168.1.17/gitLivePortfolio",
  // baseURL: "http://127.0.0.1:8000",
});
